import CommonForm from '@/components/common-form';
import { FormConfig, InputType } from '@/components/common-form/form.types';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, FormInstance, Input, message } from 'antd';
import styles from './index.module.less';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { getLocalStorage } from '@/util/util';
import Vrcode, { VerCodeRef } from '@/components/vrcode';
import { RegisterApi } from '@/api/login';

const Register = () => {
  const navigator = useNavigate();
  const formRef = useRef<FormInstance>(null);
  const [inputStatus, setInputStatus] = useState<
    'error' | 'warning' | undefined | ''
  >(undefined);
  const [codeValue, setCodeValue] = useState<string | undefined>(undefined);
  const codeRef = useRef<VerCodeRef>(null);
  const validatePhone = (_: any, value: string) => {
    // 使用正则表达式进行验证
    const regex = /^1[0-9]{10}$/; // 只允许字母、数字和下划线
    if (!regex.test(value)) {
      return Promise.reject('请填写正确手机号');
    }
    return Promise.resolve();
  };
  const validateMail = (_: any, value: string) => {
    // 使用正则表达式进行验证
    const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/; // 只允许字母、数字和下划线
    if (!regex.test(value)) {
      return Promise.reject('请填写正确邮箱');
    }
    return Promise.resolve();
  };
  const toLogin = () => {
    navigator('/login');
  };
  const formConfig: FormConfig[] = [
    {
      type: InputType.formitem,
      props: {
        name: 'phone',
        rules: [{ validator: validatePhone, validateTrigger: 'onChange' }],
      },
      children: {
        type: InputType.input,
        props: {
          prefix: <UserOutlined style={{ fontSize: 16, color: 'white' }} />,
          className: 'gray-input',
          placeholder: '手机号',
          style: {
            width: 410,
            height: 40,
          },
        },
      },
    },
    {
      type: InputType.formitem,
      props: {
        name: 'userNo',
        rules: [{ required: true, message: '请输入用户名' }],
      },
      children: {
        type: InputType.input,
        props: {
          prefix: <UserOutlined style={{ fontSize: 16, color: 'white' }} />,
          className: 'gray-input',
          placeholder: '用户名',
          style: {
            width: 410,
            height: 40,
          },
        },
      },
    },
    {
      type: InputType.formitem,
      props: {
        name: 'email',
        rules: [{ validator: validateMail, validateTrigger: 'onChange' }],
      },
      children: {
        type: InputType.input,
        props: {
          prefix: <UserOutlined style={{ fontSize: 16, color: 'white' }} />,
          className: 'gray-input',
          placeholder: '邮箱',
          style: {
            width: 410,
            height: 40,
          },
        },
      },
    },
    {
      type: InputType.formitem,
      props: {
        rules: [{ required: true, message: '请输入密码' }],
        name: 'password',
      },
      children: {
        type: InputType.password,
        props: {
          prefix: <LockOutlined style={{ fontSize: 16, color: 'white' }} />,
          className: 'gray-input',
          placeholder: '密码',
          style: {
            width: 410,
            height: 40,
          },
        },
      },
    },
  ];
  useEffect(() => {
    const userAccount = getLocalStorage('userAccount');
    if (userAccount) {
      const userAccountObj = JSON.parse(userAccount);
      formRef.current?.setFieldsValue({
        username: userAccountObj.username,
        password: userAccountObj.password,
        remember: [0],
      });
    }
  }, []);
  return (
    <div className={styles['login-wrap']}>
      <div className={styles['login-title']}>注册</div>
      <div className={styles['login-content-wrap']}>
        <div className={styles['login-content']}>
          <div className={styles['login-content-box']}>
            <CommonForm
              formConfig={formConfig}
              labelCol={{ span: 0 }}
              wrapperCol={{ span: 24 }}
              ref={formRef}
              initialValues={{
                username: '',
                password: '',
                remember: '',
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles['vrcode-wrap']}>
        <Input
          style={{ width: 206, height: 40, marginRight: 8 }}
          placeholder="验证码"
          status={inputStatus}
          value={codeValue}
          onChange={(value) => {
            setCodeValue(value.target.value);
          }}
          onFocus={() => {
            setInputStatus(undefined);
          }}
        />
        <Vrcode ref={codeRef} />
      </div>
      <Button
        style={{
          width: 164,
          height: 40,
          background: '#14BD85',
          borderRadius: 4,
          color: 'white',
          marginTop: 40,
        }}
        onClick={async () => {
          try {
            const value = await formRef.current?.validateFields();
            /*const code = codeRef.current?.getNum();
            if (codeValue?.toLocaleUpperCase() !== code?.toLocaleUpperCase()) {
              setInputStatus('error');
              return message.error('验证码错误');
            } else {
              setInputStatus(undefined);
            }*/
            const res = await RegisterApi(value);
            console.log(res);
            message.success('注册成功');
            navigator("/login");
          } catch (error) {
            console.log(error);
          }
        }}
      >
        注册
      </Button>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          color: '#666666',
          marginTop: 38,
        }}
      >
        注册即表示您同意我们的
        <a href={"/terms-service"} target={"_blank"} style={{ color: '#14BD85', marginLeft: 5 }}>
          服务条款与隐私政策
        </a>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          color: '#666666',
          marginTop: 24,
          cursor: 'pointer'
        }}
        onClick={toLogin}
      >
        已有账号?
        <span style={{ color: '#14BD85', marginLeft: 5 }}>登录</span>
      </div>
    </div>
  );
};
export default Register;
