import { Tooltip } from 'antd';
const CommonTooltip = (props: {
  title: string | React.ReactNode;
  children?: string | React.ReactNode;
}) => {
  const { title, children } = props;
  return (
    <Tooltip
      color={'#393939'}
      trigger="hover"
      title={
        <span
          style={{
            color: 'rgba(255,255,255,0.85)',
          }}
        >
          {title}
        </span>
      }
      placement="top"
    >
      <div
        className={'tooltips-container'}
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {children || title}
      </div>
    </Tooltip>
  );
};
CommonTooltip.displayName = 'CommonTooltip';
CommonTooltip.componentName = 'CommonTooltip';
export default CommonTooltip;
