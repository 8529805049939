import styles from '../product.module.less';
import {Divider, Space} from 'antd';
import {ProductDetailItem} from '@/views/products/components/common';

const ProductItem1 = () => {

  return (
    <div className={styles['product-card']}>
      <div className={`${styles['product-header']} ${styles['product1']}`}>
        <div className={styles['product-header-title']}>
          <div className={styles['product-header-icon']}></div>
          <h3>开源版BPM平台</h3>
        </div>
        <div className={styles['product-view-url']}>
          <div>
            <Space size={20}>
              <a href={"https://openportal.bpmport.com"} target={"_blank"}>前端预览</a>
              <a href={"https://openadmin.bpmport.com"} target={"_blank"}>后端预览</a>
            </Space>
          </div>
          <div style={{marginTop: '10px'}}>
            <Space>
              <a href={"https://gitee.com/lwj/flow"} target={"_blank"}>源码</a>
            </Space>
          </div>
        </div>
        <div className={styles['product-header-desc']}>
          适用于<br/>Flowable流程引擎个人学习
        </div>
      </div>
      <div className={styles['product-price']}>
        <div className={styles['free-box']}>
          免费
        </div>
      </div>
      <Divider/>
      <div className={styles['detail']}>
        <ul>
          <li>
            <ProductDetailItem>
              支持Vue2.x/Vue3.x
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              提供源码、允许二开
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              基础版表单引擎
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              基础版BPMN设计器
            </ProductDetailItem>
          </li>
        </ul>

      </div>
    </div>
  );
};
export default ProductItem1;
