import styles from './index.module.less';
const PayResult = () => {
  return (
    <div>
      <div className={styles['pay-code-wrap']}>
        <p className={styles['pay-title']}>成功</p>
      </div>
    </div>
  );
};
export default PayResult;
