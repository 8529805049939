import { FC } from 'react';
import styles from './index.module.less';
import {Col} from "antd";
interface HomepageListItemProps {
  title: string;
  desc: string;
}
const HomepageListItem: FC<HomepageListItemProps> = (props) => {
  return (
    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
      <div className={styles['homepage-list-item_wrap']}>
        <div className={styles['homepage-list-item_title']}>{props.title}</div>
        <div className={styles['homepage-list-item_desc']}>{props.desc}</div>
      </div>
    </Col>
  );
};
export default HomepageListItem;
