import { Table, TablePaginationConfig } from 'antd';
import React, {
  FC,
  isValidElement,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TableCustomProps, NewColumnsProps } from './table.types';
import styles from './index.module.less';
import './index.reset.less';
import CommonTooltip from '../common-tooltip';
import useDrag from './useDrag';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';

interface ResAndTabProps extends TableCustomProps {
  canResize?: boolean;
}
interface ResizeProps {
  dragEle: React.ReactNode;
  columnKey: string;
  dataIndex: number;
  children?: any;
}
const TableTitle: FC<ResizeProps> = ({
  dragEle,
  columnKey,
  dataIndex,
  ...restProps
}) => {
  return (
    <th {...restProps}>
      {restProps?.children}
      {React.isValidElement(dragEle) &&
        columnKey &&
        React.cloneElement(dragEle, {
          className: `data-${columnKey} dataIndex-${dataIndex} ${dragEle?.props?.className}`,
          dataindex: dataIndex,
        })}
    </th>
  );
};
const CommonTable: FC<ResAndTabProps> = (props) => {
  const {
    canResize = true,
    dataSource,
    loading,
    bordered,
    scroll,
    selection,
    locale,
    summary,
    pageSizeOptions,
    total,
    changePage,
    requestList,
    pageSize,
    currentPage,
    rowSelection,
    ...resetProps
  } = props;
  // 列宽
  const [widthData, setWdithData] = useState<{ [key: string]: number }>({});
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const [reqData, setReqData] = useState({
    page: 1,
    size: pageSize || 10,
  });
  const resizeHandle = (index: number, width: number) => {
    const current = props.columns[index];
    widthData[current.key as string] = width;
    setWdithData({ ...widthData });
  };

  const { dragEle, dragLine, startLine } = useDrag(resizeHandle);

  const pagination: TablePaginationConfig = {
    total: total || 0,
    showTotal: (total) => <div>共计{total}条</div>,
    onChange: (e, size) => {
      // 页数改变从第一页开始查询
      if (reqData.size === size) {
        changePage?.(e, size);
        reqData.page = e;
      } else {
        changePage?.(1, size);
        reqData.page = 1;
      }
      reqData.size = size;
      setCurrentPageIndex(e);
      setReqData({
        ...reqData,
      });
      requestList?.(Object.assign(props.requestData || {}, reqData));
    },
    pageSizeOptions: pageSizeOptions || ['10', '20', '50', '100'],
    current: currentPage || currentPageIndex,
    pageSize: pageSize || reqData.size,
  };
  const noData = () => {
    return (
      <div className={styles['no-data-wrap']}>
        <img
          className={styles['no-data-icon']}
          src={require('@assets/images/no-data.png')}
          alt="暂无数据"
        />
        <p className={styles['no-data-text']}>暂无数据</p>
      </div>
    );
  };
  const components = {
    header: { cell: TableTitle },
  };
  useEffect(() => {
    props.autoActionRequest &&
      props.requestList &&
      props.requestList(props.requestData);
  }, []);

  const resizeColumns = useMemo(() => {
    return props.columns.map((item, index) => {
      item.width = widthData[item.key as string] || item.width || 150;
      if (!item.render) {
        // 增加默认渲染结构
        item.render = (value: React.ReactNode) => {
          const ele = item?.ellipsis ? <CommonTooltip title={value} /> : value;
          return value === null || value === '' ? <div>-</div> : ele;
        };
      } else {
        const originalRender = item.render;
        item.render = (
          value: string | React.ReactNode,
          record: any,
          index: number,
        ) => {
          const val = originalRender?.(value, record, index);
          if (isValidElement(val)) {
            const tooltips = <CommonTooltip title={val} />;
            // 判断是否包裹过
            const ele =
              item?.ellipsis &&
              (val?.type as any).componentName !== 'CommonTooltip'
                ? tooltips
                : val;
            return ele;
          }
          return val || '-';
        };
      }
      if (item.require) {
        // 已经包裹了*
        if (
          isValidElement(item.title) &&
          item.title.props?.className?.indexOf('require-title') >= 0
        ) {
          //
        } else {
          item.title = (
            <div className={`${styles['require-title']} require-title`}>
              <span className={styles['require-star']}>*</span>
              {item.title}
            </div>
          );
        }
      }
      return {
        ...item,
        onHeaderCell: (columns: any) => {
          return {
            columnKey: columns.key,
            dataIndex: index,
            dragEle: dragEle,
          };
        },
      };
    });
  }, [props.columns, widthData]);
  useEffect(() => {
    props.resetPage && setCurrentPageIndex(1);
  }, [props.resetPage]);
  const allProps = useMemo(() => {
    if (canResize) {
      return {
        ...resetProps,
        components: components,
        columns: resizeColumns as NewColumnsProps[],
      };
    }
    return resetProps;
  }, [resetProps, components, resizeColumns, canResize]);
  return (
    <ConfigProvider locale={zhCN}>
      <div
        className={`${styles['table-wrap']} remote-table ${props.className}`}
      >
        <Table
          {...allProps}
          dataSource={dataSource}
          loading={loading}
          bordered={bordered || false}
          scroll={scroll || { x: 800, y: 'calc(100% -200px)' }}
          pagination={
            props.pagination === false
              ? false
              : props.pagination
              ? props.pagination
              : pagination
          }
          rowKey={props.rowKey || 'id'}
          rowSelection={selection || rowSelection || undefined}
          locale={{
            emptyText: noData,
            ...(locale || {}),
          }}
          summary={summary}
        />

        {dragLine}
        {startLine}
      </div>
    </ConfigProvider>
  );
};
export default memo(CommonTable);
