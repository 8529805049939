import {Key} from 'react';
import api from '.';

export const GetOrderListApi = (data: any) =>
  api({url: '/order/getOrderList', method: 'POST', data});

export const CreateOrderApi = (data: any) =>
  api({url: '/order/insert', method: 'POST', data});

export const CancelOrderApi = (id: Key) =>
  api({url: `/order/cancelById/${id}`});

export const DownLoadSourceCode = (orderNo: Key) =>
  api({url: `/order/downloadCodeZip/${orderNo}`});

export const getPayQRCode = (data: any) =>
  api({url: `/pay/payOrder`, method: 'POST', data});

export const getOrderDetailByOrderNo = (orderNo: any) =>
  api({url: `/order/getOrderDetailByOrderNo/${orderNo}`, method: 'GET'});

export const getMyProduct = () =>{
  // return Promise.resolve(result);
  return api({url: `/base/product/getMyBuyProduct`, method: 'GET'});
}

export const downloadCodeZip = (params: any) =>{
  // return Promise.resolve(result);
  return api({
    url: `/order/downloadCodeZip/${params.orderNo}`,
    method: 'GET',
    responseType: 'arraybuffer'
  });
}

  // api({url: `/base/product/getMyBuyProduct`, method: 'GET'});
