import { message } from 'antd';
import { memo, ReactNode, useEffect, useState } from 'react';
import styles from './index.module.less';
import { postUploadFile } from '@/api/upload';
import { getLocalStorage } from '@/util/util';
import { UploadProps } from './types';
import { RcFile } from 'antd/lib/upload';

const CommonUpload = (props: UploadProps) => {
  let uploadRef: any = {};
  const { postUploadFunction } = props;
  const [fileList, setFileList] = useState<string[]>([]);
  const [replace, setReplace] = useState(false);
  const [currentReplaceIndex, setCurrentReplaceIndex] = useState<number>();
  useEffect(() => {
    props.files && setFileList([...props.files]);
  }, [props.files]);
  const onClickUpload = async () => {
    setReplace(false);
    if (props.max && fileList.length >= props.max)
      return message.warning('图片超出最大上传数量');
    uploadRef.click();
  };
  const onClickReplaceUpload = async (index: number) => {
    setCurrentReplaceIndex(index);
    setReplace(true);
    uploadRef.click();
  };
  const onChange = async () => {
    try {
      const file = uploadRef.files[0];
      if (props.beforeUpload) {
        const res = await props.beforeUpload(file);
        return res && uploadFile(file);
      }
      uploadFile(file);
    } catch (error) {
      console.log(error);
    }
  };
  const uploadFile = async (file: RcFile) => {
    let form = new FormData();
    uploadRef.value = '';
    form.append('file', file);
    form.append('fileName', file.name);
    form.append('satoken', getLocalStorage('external-token') as string);
    const requeset = postUploadFunction || postUploadFile;
    const { data } = await requeset(form);
    if (props.type === 'row') {
      // 多张上传后显示
      if (replace) {
        // 替换上传
        fileList.splice(currentReplaceIndex!, 1, data);
      } else {
        // 正常上传
        fileList.push(data);
      }
      setFileList([...fileList]);
      props.onChange && props.onChange([...fileList]);
    } else {
      // 单张上传后显示
      setFileList([data]);
      props.onChange && props.onChange([data]);
    }
  };
  const onDelete = (val: string) => {
    const index = fileList.indexOf(val);
    if (index > -1) {
      fileList.splice(index, 1);
    }
    setFileList([...fileList]);
    props.onChange && props.onChange([...fileList]);
  };
  const ItemList = (): ReactNode => {
    return fileList.map((el, index) => {
      return (
        <div
          key={el + index}
          className={`${styles['upload-item']} ${
            props.type === 'row'
              ? styles['upload-row']
              : styles['upload-single']
          }`}
        >
          <img
            className={styles['upload-item-delete']}
            src={require('@/assets/images/upload-clear-icon.png')}
            alt="delete"
            onClick={() => {
              onDelete(el);
            }}
          />
          <div
            className={styles['upload-item-mask']}
            onClick={() => {
              onClickReplaceUpload(index);
            }}
          >
            <div className={styles['item-mask-text']}>替换图片</div>
          </div>
          <div
            className={`${styles['upload-item-img-wrap']} ${
              props.type === 'row'
                ? styles['upload-row-img']
                : styles['upload-single-img']
            }`}
          >
            <img src={el} className={styles['upload-item-img']} alt="img" />
          </div>
          {index === 0 && props.type === 'row' ? (
            <p className={styles['main-graph']}>主图</p>
          ) : null}
        </div>
      );
    });
  };
  return (
    <div>
      {props.type === 'single' && fileList.length === 1 ? null : (
        <div
          className={`${styles.upload} ${
            props.type === 'row'
              ? styles['upload-row']
              : styles['upload-single']
          }`}
          onClick={onClickUpload}
        >
          <div className={styles['upload-plus-wrap']}>
            <img
              className={styles['upload-plus-icon']}
              src={require('@/assets/images/add.png')}
              alt="icon"
            />
          </div>
        </div>
      )}
      <div className={styles['upload-items']}>
        {fileList.length > 0 && ItemList()}
      </div>
      <input
        style={{ display: 'none' }}
        type="file"
        accept={props.accept ? `${props.accept}/*` : undefined}
        ref={(input) => {
          uploadRef = input;
        }}
        onChange={onChange}
      />
    </div>
  );
};
CommonUpload.defaultProps = {
  type: 'row',
  max: 10,
};
export default memo(CommonUpload);
