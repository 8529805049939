import {useMemo, useState} from 'react';
import styles from './index.module.less';
import Computer from '@/assets/images/computer@3x.png';
import HomepageListItem from '@/components/homepage-list-item';
import {Carousel, Card, Flex, Button, Space, Row, Col, Divider} from 'antd';
import HomeBanner from "@/views/homepage-content/components/home-banner";
import schemaIcon1 from '@/assets/images/schema/schema-1.png';
import schemaIcon2 from '@/assets/images/schema/schema-2.png';
import schemaIcon3 from '@/assets/images/schema/schema-3.png';
import schemaIcon4 from '@/assets/images/schema/schema-4.png';
import schemaIcon5 from '@/assets/images/schema/schema-5.png';
import schemaLcsj from '@/assets/images/schema/schema-lcsj.jpg';
import schemaLcgh from '@/assets/images/schema/schema-lcgh.jpg';
import schemaLcfb from '@/assets/images/schema/schema-lcfb.jpg';
import schemaLcyy from '@/assets/images/schema/schema-lcyy.jpg';
import schemaLcyh from '@/assets/images/schema/schema-lcyh.jpg';
import cardingDdmkf from '@/assets/images/carding/carding-ddmkf.jpg';
import cardingLcsjq from '@/assets/images/carding/carding-lcsjq.png';
import cardingBdsjq from '@/assets/images/carding/carding-bdsjq.png';
import cardingQyszh from '@/assets/images/carding/carding-qyszh.png';
import cardingTymhjc from '@/assets/images/carding/carding-tymhjc.png';

import caseJstk from '@/assets/images/cases/jstk.jpg';
import caseBjsj from '@/assets/images/cases/bjsj.jpg';
import caseBrwg from '@/assets/images/cases/brwg.jpg';
import caseShdh from '@/assets/images/cases/shdh.jpg';
import caseShqh from '@/assets/images/cases/shqh.jpg';
import caseWzcg from '@/assets/images/cases/wzcg.jpg';


import Meta from "antd/es/card/Meta";

const HomepageContent = () => {

  const [schemaShowSn, setSchemaShowSn] = useState<string | undefined>("11");

  const list = [
    {
      title: '可视化',
      desc: '主流的流程设计器页面，通过可视化操作， 方便快捷的完成流程的编辑。',
    },
    {
      title: '主流UI',
      desc: '支持创建扩展至BPMNjs，完全适配Flowable和Activity。',
    },
    {
      title: '技术栈',
      desc: '最新的前端技术栈，使用BpmnJs，Vue2，ElementUI，扩展最新稳定版Flowable，方便二次开发。',
    },
    {
      title: '多终端适配',
      desc: '支持 pc、pad、mobile 多终端布局适配， 并可查看展示效果。',
    },
    {
      title: '流程设计灵活',
      desc: '可视化建模效率高，自由设定并行、串行、自由流等流程路由',
    },
    {
      title: '功能强大',
      desc: '支持统一模板管理、流程仿真、流程交接等，流程运维更简单',
    },
    {
      title: '符合主流',
      desc: '基于开源的Flowable改造，融入中国流程国情。',
    },
    {
      title: '敏捷开发',
      desc: '可基于流程引擎和开发平台，轻松集成到业务系统，快速构建业务功能。',
    },
    {
      title: '组件丰富',
      desc: '扩展了几十个事件与任务，能满足市面上99%企业的需求与业务',
    }
  ];
  const ourStrengths = [
    {
      title: 'NEW-最新技术',
      desc: '基于Flowable、Vue、BpmnJS、ElementUI、Spring Boot等技术栈开发',
    },
    {
      title: 'SDK-快速集成',
      desc: '提供SDK包，项目中只需引入JAR包，便可快速对接流程相关接口',
    },
    {
      title: 'EXTEND-组件扩展丰富',
      desc: '支持常用的几十个组件扩展，能满足市场99%客户的需求',
    },
    {
      title: 'SERVICE-售后无忧',
      desc: '始终秉承客户第一、服务至上的原则，提供免费一年技术支持，并快速响应用户',
    },
    {
      title: 'ITERATION-持续更新',
      desc: '不断的扩展新组件与持续优化系统代码，与flowable官网保持一致的版本',
    },
    {
      title: 'PRICE-价格优势突出',
      desc: '相比于市场上同行企业动不动报价几十万，我们的价格非常突出',
    },
    {
      title: 'CHOOSE-多重选择',
      desc: 'FlowablePlus流程引擎组件集成、低代码源码开发平台、服务部署、定制化开发',
    },
    {
      title: 'COOPERATE-强强联手',
      desc: '与《深入Activiti流程引擎》作者与FormMaking创始团队深度合作、交流',
    },
    {
      title: 'RELIABLE-代码的可靠性',
      desc: '项目开发者都是10年以上开发，并且在多家上市公司以及国企担任过要职，对项目的把控以及质量有保证',
    }
  ];
  const productList = [
    {
      id: '1',
      title: '低代码开发',
      desc: '',
      imgPath: cardingDdmkf,
      previewUrl: '',
      detailUrl: ''
    },
    {
      id: '12',
      title: 'Flowable-流程设计器',
      desc: '',
      imgPath: cardingLcsjq,
      previewUrl: '',
      detailUrl: ''
    },
    {
      id: '13',
      title: '企业数智化流程引擎',
      desc: '',
      imgPath: cardingQyszh,
      previewUrl: '',
      detailUrl: ''
    },
    {
      id: '14',
      title: '表单设计器',
      desc: '',
      imgPath: cardingBdsjq,
      previewUrl: '',
      detailUrl: ''
    },
    {
      id: '15',
      title: '统一集成门户',
      desc: '',
      imgPath: cardingTymhjc,
      previewUrl: '',
      detailUrl: ''
    },
  ]
  const casesList = [
    {
      id: '1',
      title: '极速退款案例',
      desc: '',
      imgPath: caseJstk,
      previewUrl: '',
      detailUrl: 'https://plus.bpmport.com/admin/modelList/bpmn?modelId=1809899888394874881'
    },
    {
      id: '16',
      title: '物资采购',
      desc: '',
      imgPath: caseWzcg,
      previewUrl: '',
      detailUrl: 'https://plus.bpmport.com/admin/modelList/bpmn?modelId=1677238015927668737'
    },
    {
      id: '14',
      title: '商户报备打电话流程',
      desc: '',
      imgPath: caseShdh,
      previewUrl: '',
      detailUrl: 'https://plus.bpmport.com/admin/modelList/bpmn?modelId=1672069858837532674'
    },
    {
      id: '15',
      title: '服务编排-团购售后缺货审核',
      desc: '',
      imgPath: caseShqh,
      previewUrl: '',
      detailUrl: 'https://plus.bpmport.com/admin/modelList/bpmn?modelId=1672069858837532675'
    },
    {
      id: '12',
      title: '边界补偿事件',
      desc: '',
      imgPath: caseBjsj,
      previewUrl: '',
      detailUrl: 'https://plus.bpmport.com/admin/modelList/bpmn?modelId=1671150314744872961'
    },
    {
      id: '13',
      title: '网关-包容网关',
      desc: '',
      imgPath: caseBrwg,
      previewUrl: '',
      detailUrl: 'https://plus.bpmport.com/admin/modelList/bpmn?modelId=1673320824530423809'
    },
  ]

  const schemaList = [{
      id: '11',
      title: '流程规划',
      iconImg: schemaIcon1,
      desc: `<h2>流程规划</h2>
           <div>
          <p>
            流程规划的本质是管理体系建模，基于企业中长期战略规划，借鉴最佳实际发展模式，构建企业的业务运行逻辑，将支撑公司战略实现的价值链上的各项职能，按照标准化的工具和方法，分解为可执行、可操作的末端流程，纵向到底直达业务一线，横向到边链接各个专业，全面实现公司业务的结构化、可视化，从顶层设计上搭建全员力出一孔的经营格局。          </p>
          <p>
            流程规划的输出物：一般包括《公司级流程架构图》、《一级流程架构图》、《流程逻辑关系图》、《流程清单》。《公司级流程架构图》整体呈现了公司的一级流程，《一级流程架构图》打开了各一级流程，这两者属于业务蓝图，体现战略思想和商业模式，但无法指导实际操作。《流程逻辑关系图》清晰呈现每个一级流程中子流程的逻辑关系，把流程之间的上下游关系表达的很清楚，像是一个区域所有河流的流向图。          </p>
          <p>
            流程规划就像设计一座大厦，顶端是企业的战略，地基是员工的执行，整座大厦各个层级的设计过程，就是流程规划。          </p>
          </div>`,
      descImg: schemaLcgh
    },{
      id: '22',
      title: '流程设计',
      iconImg: schemaIcon2,
      desc: `<h2>流程设计</h2>
            <div>
            <p>
            1、流程设计的目标
            第一步:发现企业流程中存在的问题和解决方案。首先开展流程发现工作，识别企业流程存在的问题，包括冗余、机构偏差、信息偏差等。然后，全面分析发现的问题，认识到所有可能导致问题的根源，以及解决方案在什么地方。
            </p>
          <p>
            第二步:进行流程分析。在改进流程之前，必须充分了解原有流程，检查其是否逻辑合理、可行性强、符合业务实际或者可用于解决问题等。其次，还需要记录下相关信息，例如流程的环节、步骤、部门之间的联系、异常情况等，以完整地反映流程的状态。
          </p>
          <p>
          第三步:展开流程设计。在此阶段，根据自上而下的设计角度，从总体上分析企业的流程问题，形成有效的流程设计方案，实现流程简化和优化。
          </p>
          <p>
          第四步:制定实施方案。根据第三步设计出来的方案和相关内容，制定出实施流程优化所需的实施方案，涉及流程实施资源、实施进度、实施策略等。
          </p>
          <p>
          第五步:实施流程优化。根据第四步制定的实施方案，开展实施流程优化工作，创建企业精益生产的环境，确保方案的实施高效可行。
          </p>
          <p>
            第六步:效果评估。在实施流程优化后，需要对企业实际效益进行实施效果评估，包括评估流程的完整度和实施的可操作性，记录改变的趋势。根据评估结果，不断完善流程，使其更加完善、高效。          </p>
          </div>`,
      descImg: schemaLcsj
    },{
      id: '33',
      title: '流程发布',
      iconImg: schemaIcon3,
      desc: `<h2>流程发布</h2><div>
            <p>应用程序的发布流程按照以下步骤进行:</p>
          <p>
          1.提交发布请求。开发人员将应用程序的发布请求提交给发布团队，包括发布的版本号、变更内容、发布时间等信息。 
           </p>
           <p>
           2.发布环境验证。发布团队将进行发布环境的验证，确保发布环境的正确设置和可用性。
           </p>
            <p>
           3.部署应用程序。根据发布请求中的信息，发布团队将应用程序部署到目标环境，包括服务器设置、数据库配置等。
           </p>
            <p>
          4.版本追踪和文档更新。发布团队将记录发布版本号，并更新发布文档中的版本历史和发布说明。 
           </p>
            <p>
          5.发布验证和回滚。发布团队将对发布后的应用程序进行验证，确保其在目标环境中的正常运行。如果出现问题，将及时回滚到上一个可用版本。
           </p>
            <p>
          6.完成发布。在应用程序成功发布并验证通过后，发布团队将通知相关人员发布已完成，并进行必要的清理工作。
           </p>
              
         </div>`,
      descImg: schemaLcfb
    },{
      id: '44',
      title: '流程运营',
      iconImg: schemaIcon4,
      desc: `<h2>流程运营</h2><div>
            <p>
                    流程运营就是实现企业流程资产管理、流程审计分析、流程优化管理、流程知识管理等全方位的对企业进行体系的流程管理。它是一种以规范化的构造端到端的业务流程，是一个操作性的定位描述，是为需求而设计会随着内外环境的变化而需要同步被优化。
            </p>
             <p>流程运营企业中扮演着越来越重要的角色，流程运营的管理工作渗透了企业管理的每一个环节，任何一项业务战略的实施都肯定有其有形或无形的相应操作流程。传统金字塔型的组织结构和环节复杂的业务流程已无法应付现在和未来业务的挑战。同时大量的研究也发现，在流程运营管理实践中，相当多的空有规划并未以发展的眼光看待流程运营管理，因此，导致企业的内部管理中出现了以下许多最为常见的问题：
             </p>
             <p>
            1、企业引入各种管理理念，建立各种管理体系，但各个管理体系各自为政，形成了一个个管理孤岛，企业如何通过一套有效的方法进行多套管理体系集中落地？<br/>
            2、如何利用有效的方法和工具来开展流程绩效评估、合规性分析、缺陷分析、风险分析？<br/>
            3、如何通过最佳实践传承途径，对流程知识系统的认识、沉淀、共享和创新，保障流程知识传递和投资的延续？<br/>
            4、对已知的流程如何落地到系统中，企业中每个岗位需要做哪些工作是否一目了然。
            </p>
                  </div>`,
      descImg: schemaLcyy
    },{
      id: '55',
      title: '流程优化',
      iconImg: schemaIcon5,
      desc: `<h2>流程优化</h2><div>
          <p>
                1．流程再造（Business Process Reengineering，BPR）<br/>
          针对组织业务流程的基本问题进行反思，并对流程进行彻底的重新设计，称为流程再造。流程再造能够使流程在成本、质量、服务和效率等衡量组织绩效的重要指标上取得显著的进展。
          </p>
          <p>
          2．流程优化（Business Process Improvement，BPI）<br/>
          组织对现有业务流程梳理、完善和改进的过程，称为流程的优化。这里的“流程优化（BPI）”是狭义的，它与流程再造（BPR）、流程活动改善（BPAI）共同组成了广义概念的流程优化过程组。
          </p>
          <p>
          3．流程活动改善（Business Process Activity Improvement，BPAI）<br/>
          流程活动改善是组织对现有业务流程中的活动，从成本、质量、服务和效率上进行的改善，它关注的是流程中单个活动的改善。流程活动的执行角色根据在活动实际开展过程中的体验，从提升流程活动的执行效率和效果方面提出改进建议，改善流程活动，从而达到提升流程整体的效率和效果的目的。
          </p>
      </div>`,
      descImg: schemaLcyh
    }
  ]

  const ListItems = useMemo(() => {
    const contentList = list.map((item, index) => (
      <HomepageListItem key={index} title={item.title} desc={item.desc} />
    ));
    return <Row gutter={[24, 24]}>{contentList}</Row>
  }, [list]);

  const OurStrengthsItems = useMemo(() => {
    const contentList = ourStrengths.map((item, index) => (
      <HomepageListItem key={index} title={item.title} desc={item.desc} />
    ));
    return <Row gutter={[24, 24]} >{contentList}</Row>
  }, [list]);

  const productListItems = useMemo(() => {
    const proList = productList.map((item)=> {
      return <Col xs={12} sm={12} md={12} lg={4} xl={4} className={styles['solutions-item']}>
          <div key={item.id} >
          <div className={styles['product-img']}>
            <img src={item.imgPath} alt={item.title}/>
          </div>
          <h3>
            {item.title}
          </h3>
          <div>
            {item.desc}
          </div>
          <div className={styles['product-btn']}>
          </div>
        </div>
      </Col>
    })
    return <Row gutter={[0, 20]} justify="space-between" >{proList}</Row>
  }, [productList]);

  const caseListItems = useMemo(() => {
    const caseList = casesList.map((item)=> {
      return <Col xs={12} sm={12} md={12} lg={8} xl={8} >
          <a href={item.detailUrl} target={"_blank"}>
            <Card hoverable style={{overflow: 'hidden'}}
                  cover={<img alt={item.title} src={item.imgPath} style={{width: '100%'}}/>}
            >
                <Meta title={item.title} />
            </Card>
          </a>
      </Col>
    })
    return <Row gutter={[10, 10]} justify="space-evenly" >{caseList}</Row>
  }, [casesList]);

  const showSchemaDetail = (item) => {
    setSchemaShowSn(item.id);
  }

  const schemaListItems = useMemo(() => {
    const list= schemaList.map((item) => {
      return <div key={item.id} style={{display: schemaShowSn === item.id ? 'block' : 'none'}}>
        <Row gutter={[30, 20]} >
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div dangerouslySetInnerHTML={{ __html: item.desc }} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{textAlign: 'center'}}>
            <img src={item.descImg} style={{width: '100%', maxWidth: '600px'}}/>
          </Col>
        </Row>
      </div>;
    });
    return <div style={{minHeight: '200px'}} className={styles['container-box']}>
      {list}
    </div>
  }, schemaList)

  const schemaListIcons = useMemo(() => {
    return schemaList.map((item) => {
      return <div key={item.id} className={`${styles['schema-item']} ${styles['container-box']} ${item.id === schemaShowSn?styles['selected']:styles['no-selected']}`} >
        <div onMouseOver={()=>showSchemaDetail(item)} >
          <img className={styles['schema-item-icon']} src={item.iconImg} />
          <br/>
          <span>
            {item.title}
          </span>
        </div>
      </div>
    });
  }, schemaList)

  return (
    <div>
      <HomeBanner />

      <h3 className={styles['second-title']}>
        经典案例
      </h3>

      <div className={styles['home-cases']}>
        {caseListItems}
      </div>

      <h3 className={styles['second-title']}>
        流程数智化管理方案
      </h3>

      <div className={styles['home-solutions']}>
        <Flex justify={"space-between"} vertical={false}>
          {productListItems}
        </Flex>
      </div>

      <h3 className={styles['second-title']}>
        流程梳理设计
      </h3>
      <div className={styles['flow-schema']}>
        {schemaListIcons}
      </div>
      <div>
        {schemaListItems}
      </div>

      <div className={styles['homepage-list-wrap']}>
        <Divider ><h2>我们的优势</h2></Divider>
        <div className={styles['homepage-list-grid']}>{OurStrengthsItems}</div>
      </div>
      <div className={styles['homepage-list-wrap']}>
        <Divider ><h2>特性</h2></Divider>
        <div className={styles['homepage-list-grid']}>{ListItems}</div>
      </div>
    </div>
  );
};
export default HomepageContent;
