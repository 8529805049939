import styles from '../product.module.less';
import {CheckOutlined} from "@ant-design/icons";
import {Statistic, Tag} from "antd";
import BuyButton from '@/views/products/components/common/BuyButton';
import {useState} from "react";

const {Countdown} = Statistic;


function ProductDetailItem({children}) {
  const template = (
    <div className={styles['p-item']}>
      <div className={styles['p-item-icon']}>
        <CheckOutlined style={{fontSize: '16px', color: 'rgb(25, 190, 107)'}}/>
      </div>
      <div className={styles['p-item-txt']}>
        {children}
      </div>
    </div>
  );
  return template;
}

function ProductPrice({originPrice, currentPrice, deadline}) {
  const [showCountDown, setShowCountDown] = useState(false)

  if(deadline && deadline >= new Date().getTime()){
    setShowCountDown(false);
  }

  return (
    <div className={styles['price-wrapper']} style={{margin: '10px auto'}}>
      <div className={styles['origin-price']}>原价:<span
        className={styles['money-unit']}>￥</span><span
        className={styles['money']}>{originPrice}</span>/永久
      </div>
      <div className={styles['current-price']}>限时特价:<span
        className={styles['money-unit']}>￥</span><span
        className={styles['money']}>{currentPrice}</span>/永久
      </div>
      {
        showCountDown&&deadline?<div className={styles['deadline-wrapper']}>
          <Tag color="volcano" className={styles['deadline-wrapper-tag']}
               style={{marginInlineEnd: '0px'}}>
            <Countdown title="" value={deadline} format="DD天HH时mm分ss秒"/>
            <span>后恢复原价</span>
          </Tag>
        </div>:<div></div>
      }
    </div>
  );
}

export {
  ProductDetailItem,
  ProductPrice,
  BuyButton
};
