import { CheckOutlined } from '@ant-design/icons';
import styles from './index.module.less';
import { FC, useContext, useMemo } from 'react';
import { ColorConfigProps, ListProps } from '..';
import { LocalStorageContext } from '@/views/provider/storage-provider';
import { useNavigate } from 'react-router';
import { Space } from 'antd';

import { setLocalStorage } from '@/util/util';
export interface ProductCardProps {
  index: number;
  data: ListProps;
}
const BtnColorConfig: ColorConfigProps = {
  0: '#AF743C',
  1: '#327D73',
  2: '#477CBD',
};
const ProductCard: FC<ProductCardProps> = (props) => {
  const { data, index } = props;
  const userInfo = useContext(LocalStorageContext);
  const navigator = useNavigate();
  const ListItems = useMemo(() => {
    const list = data.introduce.split(',');
    console.log(list);
    return list.map((item, index) => (
      <li
        key={index}
        className={styles['list-item']}
        style={{
          fontSize: 14,
          color: 'white',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CheckOutlined
          style={{ fontSize: 14, color: 'white', marginRight: 7 }}
        />
        {item}
      </li>
    ));
  }, [data]);
  const toBuy = (item) => {
    if (!userInfo) {
      navigator('/login');
    } else {
      if(item.productNo === 'DZ0001'){
        navigator('/contact');
        return;
      }
      setLocalStorage('goodsInfo', data);
      navigator('/order', { state: data });
      window.scrollTo(0, 0); // 滚动到页面顶部
    }
  };
  return (
    <div
      style={{ width: '100%', height: '100%', padding: '20px 0px 28px 0px' }}
    >
      <div style={{ width: '100%', textAlign: 'center', fontSize: 22 }}>
        {data.productName}
      </div>
      <ul
        style={{ width: '100%', marginTop: 23, paddingLeft: '17%' }}
        className={styles['list-wrap']}
      >
        {ListItems}
      </ul>
      <div className={styles['btn-wrap']}>
        <div
          style={{
            width: 83,
            height: 34,
            textAlign: 'center',
            lineHeight: '34px',
            background: BtnColorConfig[index],
            borderRadius: 4,
            color: 'white',
            fontSize: 14,
            cursor: 'pointer',
          }}
          onClick={()=>{
            toBuy(data)
          }}
        >
          {
            data.productNo !== 'DZ0001'?'前往购买':'联系我们'
          }

        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 12,
            color: 'white',
            marginTop: 9,
          }}
        >
          {
            data.productNo !== 'DZ0001'?
              <Space>
                <span style={{textDecoration: 'line-through'}}>¥{data.originalPrice}</span>
                <span>¥{data.amount}</span>
              </Space>:<div></div>
          }
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 12,
            color: 'white',
            marginTop: 9,
          }}
        >
          <a style={{color: "white"}} href={"//www.bpmport.com/bpmn/designer/"} target={"_blank"}>
            <span style={{ marginRight: 8 }}>查看演示</span>
          </a>
          <a style={{color: "white"}} href={"//www.bpmport.com/bpmn/docs/"} target={"_blank"}>
            <span>文档手册</span>
          </a>
        </div>
      </div>
    </div>
  );
};
export default ProductCard;
