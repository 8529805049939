import CommonForm from '@/components/common-form';
import {FormConfig, InputType} from '@/components/common-form/form.types';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Button, FormInstance, Input, message} from 'antd';
import styles from './index.module.less';
import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router';
import {getLocalStorage} from '@/util/util';
import {VerCodeRef} from '@/components/vrcode';
import {ResetPwdApi, SendEmailApi} from '@/api/login';

const ChangePwd = () => {
  const navigator = useNavigate();
  const formRef = useRef<FormInstance>(null);
  const [inputStatus, setInputStatus] = useState<
    'error' | 'warning' | undefined | ''
  >(undefined);
  const [codeValue, setCodeValue] = useState<string | undefined>(undefined);
  const [isSendEmail, setIsSendEmail] = useState<Boolean | undefined>(false);
  const [count, setCount] = useState<number | undefined>(60);
  const codeRef = useRef<VerCodeRef>(null);
  const timerRef = useRef(null) // 记录时间的定时器
  const timerCount = 60 // 默认60秒

  const validateMail = (_: any, value: string) => {
    // 使用正则表达式进行验证
    const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/; // 只允许字母、数字和下划线
    if (!regex.test(value)) {
      return Promise.reject('请填写正确邮箱');
    }
    return Promise.resolve();
  };
  let regExp = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/
  const cutCount = () => {
    setCount((prevState) => prevState - 1) // 为什么这里要用函数- 如果用count 发现count闭包了 不会发生变化了
  }

  const toChangePwd = () => {
    navigator('/change-pwd');
  };
  const toLogin = () => {
    navigator('/login');
  };
  const formConfig: FormConfig[] = [
    {
      type: InputType.formitem,
      props: {
        name: 'email',
        rules: [{ validator: validateMail, validateTrigger: 'onChange' }],
      },
      children: {
        type: InputType.input,
        props: {
          prefix: <UserOutlined style={{ fontSize: 16, color: 'white' }} />,
          className: 'gray-input',
          placeholder: '邮箱',
          style: {
            width: 410,
            height: 40,
          },
        },
      },
    },
    {
      type: InputType.row,
      children: [
        {
          type: InputType.col,
          children:{
            type: InputType.formitem,
            props: {
              name: 'emailCode',
              rules: [{ required: true, message: '请输入验证码' }],
            },
            children: {
              type: InputType.input,
              props: {
                prefix: <UserOutlined style={{ fontSize: 16, color: 'white' }} />,
                className: 'gray-input',
                placeholder: '验证码',
                style: {
                  width: 200,
                  height: 40,
                },
              },
            },
          },
        },
        {
          type: InputType.col,
          children: {
            type: InputType.button,
            props: {
              type:'primary',
              id: 'sendEmailBtn',
              name: 'sendEmail',
              time: 60,
              showtime: 'true',
              style: {
                width: 190,
                height: 40,

                marginLeft: 20,
              },
              children: "发送邮箱",
              onClick: async ()=>{
                const email = formRef?.current?.getFieldValue('email');
                if(!email){
                  message.error('请输入邮箱！')
                  return Promise.reject('请输入邮箱！');
                }
                if (regExp.test(email)) {
                  const result = await SendEmailApi(email);
                  const {msg, success, data} = result;
                  if(success){
                    message.success(data)
                    return Promise.resolve();
                  }else{
                    message.error(msg)
                    return Promise.reject('邮箱不存在！');
                  }
                }else{
                  message.error('邮箱格式不正确！')
                  return Promise.reject('邮箱格式不正确！');
                }
              }
            }
          },
        }
      ]
    },
    {
      type: InputType.formitem,
      props: {
        rules: [{required: true, validator: (_:any, value: string)=>{
          if(value.length<6){
            // return new Error("密码至少6位！");
            return Promise.reject("密码至少6位！");
          }else{
            return Promise.resolve();
          }
          }, message: '请输入密码' }],
        name: 'password',
      },
      children: {
        type: InputType.password,
        props: {
          prefix: <LockOutlined style={{ fontSize: 16, color: 'white' }} />,
          className: 'gray-input',
          placeholder: '新密码',
          style: {
            width: 410,
            height: 40,
          },
        },
      },
    },
    {
      type: InputType.formitem,
      props: {
        rules: [(form: FormInstance)=>{
          return {
            validator: (_:any, value: string)=>{
              if(form.getFieldValue('password') !== value){
                return Promise.reject(new Error("两次输入密码不一致"));
              }else{
                return Promise.resolve();
              }
            },
            message: '两次输入密码不一致'
          }
        }],
        name: 'newPassword',
      },
      children: {
        type: InputType.password,
        props: {
          prefix: <LockOutlined style={{ fontSize: 16, color: 'white' }} />,
          className: 'gray-input',
          placeholder: '确认密码',
          style: {
            width: 410,
            height: 40,
          },
        },
      },
    },
  ];
  useEffect(() => {
    const userAccount = getLocalStorage('userAccount');
    if (userAccount) {
      const userAccountObj = JSON.parse(userAccount);
      formRef.current?.setFieldsValue({
        username: userAccountObj.username,
        password: userAccountObj.password,
        remember: [0],
      });
    }
    /*clearTimeout(timer)
    timer = setTimeout(() => {
      if (count > 1) {
        setCount(count - 1)
      } else {
        setIsSendEmail(false)
      }
    }, 1000)*/
  }, []);
  useEffect(() => {
    if (count === 0) {
      clearInterval(timerRef.current) // 清空定时器
      setCount(timerCount) // 重新将技术器设置为60秒
    }
  }, [count])

  return (
    <div className={styles['login-wrap']}>
      <div className={styles['login-title']}>忘记密码</div>
      <div className={styles['login-content-wrap']}>
        <div className={styles['login-content']}>
          <div className={styles['login-content-box']}>
            <CommonForm
              formConfig={formConfig}
              labelCol={{ span: 0 }}
              wrapperCol={{ span: 24 }}
              ref={formRef}
              initialValues={{
                username: '',
                password: '',
                remember: '',
              }}
            />
          </div>
        </div>
      </div>
      {/*<div className={styles['vrcode-wrap']}>
        <Input
          placeholder="验证码"
          style={{ width: 206, height: 40, marginRight: 8 }}
          onPressEnter={(e) => e.preventDefault()}
          onChange={(value) => {
            setCodeValue(value.target.value);
          }}
          status={inputStatus}
          value={codeValue}
          onFocus={() => {
            setInputStatus(undefined);
          }}
        />
        <Button
          style={{ width: 206, height: 40, marginRight: 8 }}
          disabled={isSendEmail}
          onClick={handleSendEmail}
        >
          {isSendEmail ? (
            <span>{count}秒后重新发送</span>
          ) : (
            <span>发送</span>
          )}
        </Button>
      </div>*/}
      <Button
        style={{
          width: 164,
          height: 40,
          background: '#14BD85',
          borderRadius: 4,
          color: 'white',
          marginTop: 40,
        }}
        onClick={async () => {
          try {
            const value = await formRef.current?.validateFields();

            const { success, msg} = await ResetPwdApi(value);

            if(success){
              message.success(msg);
              setTimeout(()=>{
                toLogin();
              }, 1000)
            }else{
              message.error(msg);
              formRef.current?.resetFields(['emailCode'])
            }
          } catch (error) {
            console.log(error);
          }
        }}
      >
        重置密码
      </Button>
    </div>
  );
};
export default ChangePwd;
