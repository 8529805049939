import styles from './index.module.less';
import {useEffect, useState} from "react";
import {getOrderDetailByOrderNo, getPayQRCode} from "@/api/order";
import {useParams} from "react-router";
import CountDownWrapper from "@/views/create-order/components/CountDownWrapper";
import { useNavigate } from 'react-router';
import { Spin ,Tag,Tooltip  } from 'antd';

const PayResult = () => {
  const [payQRCodeImg, setPayQRCodeImg] = useState<String>("");
  const [orderInfo, setOrderInfo] = useState<String>("");
  const [lastTime, setLastTime] = useState<Number>(0);
  const navigator = useNavigate();
  const params = useParams()
  const init = async () => {
    try {
      const {data} = await getPayQRCode({orderNo: params.orderNo});
      const {data: info} = await getOrderDetailByOrderNo(params.orderNo);
      setOrderInfo(info);
      setPayQRCodeImg(data);
      if(!info.payLastDate){
        info.payLastDate = new Date(new Date(info.createdTime).getTime() + 10*60*1000)
      }
      setLastTime(new Date(info.payLastDate).getTime());
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    init();
  }, []);
  useEffect(() => {

  }, [lastTime]);

  console.log(lastTime, '1111111111111111');
  if(lastTime<0){
    navigator("/order/payErrorResult");
  }
  if(lastTime === 0){
    return <Spin ><div style={{height: '300px'}} className={styles['pay-code-container']}></div></Spin>
  }

  return (
    <div>
      <div className={styles['pay-code-container']}>
        <div>
          <img src={payQRCodeImg} style={{width: '200px', height: '200px'}}/>
        </div>
        <div style={{marginLeft:'20px'}}>
          <Tag color={"error"}>
            <div style={{color: "#f7603e"}} className={styles['pay-last-time']}>
                支付剩余时间：
                <CountDownWrapper
                  expire={lastTime}
                  showDomStruct={true}
                />

            </div>
          </Tag>
          <br/>
          <div className={styles['pay-safe']}>
            已通过安全检测，请使用微信APP扫码支付
          </div>
          <div className={styles['pay-amount']}>
            ¥ <strong> {orderInfo?.amount}</strong>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PayResult;
