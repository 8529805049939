import React, {
  FC,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';
import {getVerifyCode} from "@/api/login";
interface VerCodeProps {
  width?: number;
  height?: number;
  codeLength?: number;
  fontSizeMin?: number;
  fontsizeMax?: number;
  backgroundColorMin?: number;
  backgroundColorMax?: number;
  contentWidth?: number;
  contentHeight?: number;
  colorMin?: number;
  colorMax?: number;
  lineColorMin?: number;
  lineColorMax?: number;
  onChange?: (value: string) => void;
  value?: string;
}
export interface VerCodeRef {
  getNum: () => string;
}
const VerCode: ForwardRefRenderFunction<VerCodeRef, VerCodeProps> = (
  props,
  ref,
) => {
  const {
    codeLength = 4,
    fontSizeMin = 20,
    fontsizeMax = 22,
    backgroundColorMin = 240,
    backgroundColorMax = 250,
    contentWidth = 96,
    contentHeight = 38,
    colorMin = 10,
    colorMax = 20,
    lineColorMin = 40,
    lineColorMax = 180,
  } = props;
  const randomNum = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min) + min);
  };
  const [_randomCode, setCode] = useState('');
  useImperativeHandle(ref, () => {
    return {
      getNum: () => {
        console.log(_randomCode);
        return _randomCode;
      },
    };
  });
  const canvas = useRef<HTMLCanvasElement>(null);
  const randomColor = (min: number, max: number) => {
    const r = randomNum(min, max);
    const g = randomNum(min, max);
    const b = randomNum(min, max);
    return `rgb(${r},${g},${b})`;
  };
  const genElement = (code: string) => {
    const _canvas = canvas.current;
    const ctx = _canvas!.getContext('2d');
    ctx!.textBaseline = 'bottom';
    ctx!.fillStyle = randomColor(backgroundColorMin, backgroundColorMax);
    ctx!.fillRect(0, 0, contentWidth, contentHeight);
    for (let i = 0; i < code.length; i++) {
      //   console.log(code[i]);
      drawText(ctx!, code[i], i, code);
    }
    drawLine(ctx!);
    drawDot(ctx!);
  };
  const drawText = (
    ctx: CanvasRenderingContext2D,
    txt: string,
    i: number,
    code: string,
  ) => {
    ctx.fillStyle = randomColor(colorMin, colorMax);
    const fontSize = randomNum(fontSizeMin, fontsizeMax);
    ctx.font = fontSize + 'px SimHei';
    const padding = 10;
    const offset = (contentWidth - 40) / (code.length - 1);
    let x = padding;
    if (i > 0) {
      x = padding + i * offset;
    }
    let y = randomNum(fontsizeMax, contentHeight - 5);
    if (fontSize > 40) {
      y = 40;
    }
    const deg = randomNum(-10, 10);
    ctx.translate(x, y);
    ctx.rotate((deg * Math.PI) / 180);
    console.log(txt);
    ctx.fillText(txt, 0, 0);
    ctx.rotate((-deg * Math.PI) / 180);
    ctx.translate(-x, -y);
  };
  const drawLine = (ctx: CanvasRenderingContext2D) => {
    for (let i = 0; i < 1; i++) {
      ctx.strokeStyle = randomColor(lineColorMin, lineColorMax);
      ctx.beginPath();
      ctx.moveTo(randomNum(0, contentWidth), randomNum(0, contentHeight));
      ctx.lineTo(randomNum(0, contentWidth), randomNum(0, contentHeight));
      ctx.stroke();
    }
  };
  const drawDot = (ctx: CanvasRenderingContext2D) => {
    for (let i = 0; i < 100; i++) {
      ctx.fillStyle = randomColor(0, 255);
      ctx.beginPath();
      ctx.arc(
        randomNum(0, contentWidth),
        randomNum(0, contentHeight),
        1,
        0,
        2 * Math.PI,
      );
      ctx.fill();
    }
  };
  useEffect(() => {
    // randomCode();
    handleChangeVerifyCode();
  }, []);

  const handleChangeVerifyCode = ()=>{
    getVerifyCode().then(res=>{
      const {data} = res;
      setCode(data);
    })
  }

  const randomCode = () => {
    let random = '';
    const str = 'QWERTYUPLKJHGFDSAZXCVBNMqwertyupkjhgfdsazxcvbnm1234567890';
    for (let i = 0; i < codeLength; i++) {
      const index = Math.floor(Math.random() * 57);
      random += str[index];
    }
    console.log(123);
    setCode(random);
    genElement(random);
    props.onChange?.(random);
  };
  //   const module = randomCode();
  return (
    <div>
      <img src={_randomCode} style={{cursor: 'pointer', width: '100px' , height: '40px'}} onClick={()=>{
        handleChangeVerifyCode();
      }}/>
      {/*<canvas
        ref={canvas}
        width="100"
        height="40"
        onClick={() => {
          randomCode();
        }}
      ></canvas>*/}
    </div>
  );
};

export default forwardRef(VerCode);
