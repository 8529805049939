import styles from '../product.module.less';
import {Divider, Space} from 'antd';
import {ProductDetailItem, ProductPrice, BuyButton} from '@/views/products/components/common';

const ProductItem4 = () => {

  return (
    <div className={styles['product-card']}>
      <div className={`${styles['product-header']} ${styles['product4']}`}>
        <div className={styles['product-header-title']}>
          <div className={styles['product-header-icon']}></div>
          <h3>FlowablePlus-若依</h3>
        </div>
        <div className={styles['product-view-url']}>
          <div>
            <Space size={20}>
              <a href={"https://ruoyi.bpmport.com"} target={"_blank"}>若依版预览</a>
            </Space>
          </div>
          <div  style={{marginTop: '10px'}}>
            <Space size={20}>
              <a href={"https://plus.bpmport.com"} target={"_blank"}>插件版预览</a>
            </Space>
          </div>
        </div>
        <div className={styles['product-header-desc']}>
          简洁风格、强大配置、无缝对接
        </div>
      </div>
      <div className={styles['product-price']}>
        <div className={styles['price-box']}>
          <ProductPrice originPrice={39999} currentPrice={19999}/>
        </div>
        <div className={styles['link']}>
          <BuyButton productNo={"2"}/>
        </div>
      </div>
      <Divider/>
      <div className={styles['detail']}>
        <ul>
          <li>
            <ProductDetailItem>
              支持Vue2.x/Vue3.x
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              提供源码、允许二开
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              常用的组件扩展功能
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              开具电子发票
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              企业版BPMN设计器
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              DMN决策引擎
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              支持服务编排(rest、springCloud、dubbo)
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
            支持审批，驳回，前加签，后加签，暂存，转办，签收，撤回，转阅
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              支持待办、已办、我发起等功能
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              支持redis缓存模型数据
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              支持Rest、消息、邮件、抄送、微服务等服务任务
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              替换flowable原生的de_model，实现6.x到7.x的无缝升级
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              技术支持 1个月（工作日）
            </ProductDetailItem>
          </li>
        </ul>

      </div>
    </div>
  );
};
export default ProductItem4;
