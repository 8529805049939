/*
 * @Description:
 * @version:
 * @Author: liuhong
 * @Date: 2022-05-27 14:14:16
 * @LastEditors: liuhong
 * @LastEditTime: 2022-05-27 16:50:41
 */
import { Button, ButtonProps } from 'antd';
import { FC, memo, useEffect, useState } from 'react';
import './index.less';
interface ButtonChild extends ButtonProps {
  time?: number;
  showtime?: string;
  onClick?: () => void;
}
const CommonButton: FC<ButtonChild> = (props) => {
  // let baseTime = props.time || 60;
  let [baseTime, setBaseTime] = useState(60);
  let [baseDisabled, setBaseDisabled] = useState(false);

  useEffect(() => {
    if (props.time) setBaseTime(props.time);
  }, [props.time]);
  return (
    <Button
      {...props}
      disabled={baseDisabled}
      onClick={() => {
        const result = props.onClick && props.onClick();

        try {
          if (props.showtime === 'true') {
            if(result){
              debugger;
              result.then(res=>{
                setBaseDisabled(true);
                const times = setInterval(() => {
                  const time = baseTime--;
                  setBaseTime(time);
                  if (time === 0) {
                    clearInterval(times);
                    setBaseTime(props.time || 60);
                    setBaseDisabled(false);
                    return;
                  }
                }, 1000);
              }).catch(e=>{
                console.error(e)
              });
            }
          }
        } catch (error) {
          console.log(error);
        }
      }}
    >
      {props.children + `${!baseDisabled ? '' : `(${baseTime})`}`}
    </Button>
  );
};
export default memo<FC<ButtonChild>>(CommonButton);
