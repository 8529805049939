import styles from './index.module.less';
import 'swiper/less';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper';
import './index.less';
import Warn from '@/assets/images/warn.png';
import { getProductList } from '@/api/product';
import { Key, useEffect, useMemo, useState } from 'react';
import ProductCard from './components';
import {Spin} from 'antd';
export interface ColorConfigProps {
  [key: number]: string;
}
const ColorConfig: ColorConfigProps = {
  0: 'swiper-release',
  1: 'swiper-source',
  2: 'swiper-ome',
};
export interface ListProps {
  amount: number;
  codeUrl: string;
  id: Key;
  introduce: string;
  productName: string;
  productNo: string;
}
const CommercialLicense = () => {
  const [list, setList] = useState<ListProps[]>([]);
  const [productLoading, setProductLoading] = useState<Boolean>(true);
  const getProduct = async () => {
    try {
      // loading
      setProductLoading(true)
      const { data } = await getProductList();
      setProductLoading(false);
      console.log(data, '-------product');
      setList(data);
      // hide loading...
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getProduct();
  }, []);
  const ProductList = useMemo(() => {
    return list.map((item, index) => (
      <SwiperSlide
        className={ColorConfig[index]}
        key={item.id}
        style={{
          color: 'white',
          width: '50%',
          height: 504,
        }}
      >
        <ProductCard data={item} index={index} />
      </SwiperSlide>
    ));
  }, [list]);
  return (
    <div className={styles['commercial-license-wrap']}>
      <div className={styles['commercial-license-title']}>购买商业许可</div>
      <div className={styles['commercial-license-desc']}>
        一次性授权，终身使用
      </div>
      <div className={styles['commercial-license-swiper_wrap']}>
        <Spin spinning={productLoading}>
          <Swiper
            loop={true}
            loopPreventsSliding={true}
            loopedSlides={1}
            style={{ width: '100%' , height: '500px'}}
            slidesPerView={3}
            centeredSlides
            initialSlide={1}
            effect="coverflow"
            simulateTouch={true}
            slideToClickedSlide={true}
            modules={[EffectCoverflow]}
            coverflowEffect={{
              rotate: 0,
              stretch: 50,
              depth: 50,
              scale: 0.9,
              modifier: 1,
              slideShadows: false,
            }}
          >
            {ProductList}
          </Swiper>
        </Spin>
      </div>
      <div className={styles['commercial-license-authorization']}>
        <div>
          普通源码版、高级源码版和企业定制版都提供源代码，企业定制化版本请先加微信群，我方在根据具体的业务进行合理报价。
        </div>
        <div style={{ marginTop: 22 }}>
          购买授权之前，请认真阅读 《
          <span style={{ color: '#14BD85' }}>授权协议</span>》
        </div>
        <div style={{ marginTop: 22 }}>
          <span style={{ color: '#F56F7D' }}>注意：</span>
          【FlowablePlus流程引擎】受国家计算机软件著作权保护，任何人不得恶意分享产品源代码或进行倒卖等行为，违者必究。
        </div>
      </div>
      <div className={styles['commercial-license-problem_wrap']}>
        <div className={styles['commercial-license-problem_title']}>
          常见问题
        </div>
        <div className={styles['commercial-license-problem_subtitle']}>
          我该选择哪一个产品？
        </div>
        <p className={styles['commercial-license-problem_desc']}>
          如果您不确定购买普通源码版还是高级源码版，可先加群(群号见右下角)向群主咨询，我们会为您进行专业的分析并推荐。
          <br />
          普通源码版和高级源码版基本涵盖了BPMN大多数组件的扩展，企业或个人的业务场景基本能满足。
        </p>
        <div className={styles['commercial-license-problem_subtitle']}>
          哪里可看到不同版本价格？
        </div>
        <p className={styles['commercial-license-problem_desc']}>
          普通源码版和高级源码版：点击右上角的“购买”，即可查看当前对应版本的具体价格。
          企业定制版：根据您的具体业务需求和功能，由我方进行合理的评估报价。经双方评估同意后，在进行后续签约购买与合作。
        </p>
        <div className={styles['commercial-license-problem_subtitle']}>
          什么是「企业定制版」，和「普通源码版」「高级源码版」有什么区别？
        </div>
        <p className={styles['commercial-license-problem_desc']}>
          企业定制版是根据贵方的需求进行功能定制化、服务定制化、授权定制化。我们有专业的研发和售前、售后团队，
          根据贵方的需求进行合理的评估与报价。
          <br />
          三个版本都提供源码，贵方都可以进行二开，但仅限于公司内部与个人使用。
          普通源码版只提供为期一年的免费升级，到期后如您还需提供升级服务，需重新购买此产品。
          高级源码版只提供为期一年的免费升级，到期后如您还需提供升级服务，需每年支付总价格的20%费用。
          企业定制版提供“永久”的BPMN扩展组件的免费升级。
        </p>
        <div className={styles['commercial-license-problem_subtitle']}>
          是否支持补差价升级？
        </div>
        <p className={styles['commercial-license-problem_desc']}>
          从您购买授权日开始，3个版本三个月内都可补差价升级。
          <br />
          如果您选择的企业定制版，公共的BPMN组件扩展（非业务）可提供“永久”的免费升级。
        </p>
        <div className={styles['commercial-license-problem_subtitle']}>
          如何申请开发票？
        </div>
        <p className={styles['commercial-license-problem_desc']}>
          购买授权成功后，我们会第一时间拉您进售后服务群，您可在群里联系群主或管理员。我们将会第一时间登记，并在
          20 个工作日内为您开具，开具发票为增值税（电子）普通发票。
        </p>
        <div className={styles['commercial-license-problem_subtitle']}>
          授权后将提供怎样的服务？
        </div>
        <p className={styles['commercial-license-problem_desc']}>
          购买授权成功后，除了能够获得对应的产品源码之外，还享有以下售后服务：
          <br />
          1.
          使用过程中如遇到相关技术问题，可随时随地在售后交流群中咨询，我们第一时间有专业的技术人员为您提供解答与帮助服务。
          <br />
          2. 在产品使用过程中，您将享有反映和提出意见的优先权，如合理，您的意见将被我们作为首要考虑。
        </p>
      </div>
      <div className={styles['commercial-license-concat']}>
        <img src={Warn} style={{ width: 16, height: 16, marginRight: 8 }} />
        <span>
          如果有其它疑问，请 <span style={{ color: '#14BD85' }}>联系我们</span>
        </span>
      </div>
    </div>
  );
};
export default CommercialLicense;
