import CommonForm from '@/components/common-form';
import styles from './index.module.less';
import { FormConfig, InputType } from '@/components/common-form/form.types';
import { Button, Radio, message } from 'antd';
import { useRef, useState } from 'react';
import { FormInstance } from 'antd/es/form/Form';
import { SaveConcatInfo } from '@/api/contact';
import ContactUsQQ from '@/assets/images/qq-qr-code.jpg';

const ContactUs = () => {
  const formRef = useRef<FormInstance<any>>(null);
  const [loading, setLoading] = useState(false);
  const [radioValue, setRadioValue] = useState(1);
  const validatePhone = (_: any, value: string) => {
    // 使用正则表达式进行验证
    const regex = /^1[0-9]{10}$/; // 只允许字母、数字和下划线
    if (!regex.test(value)) {
      return Promise.reject('请填写正确手机号');
    }
    return Promise.resolve();
  };
  const validateMail = (_: any, value: string) => {
    // 使用正则表达式进行验证
    const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/; // 只允许字母、数字和下划线
    if (!regex.test(value)) {
      return Promise.reject('请填写正确邮箱');
    }
    return Promise.resolve();
  };
  const formConfig: FormConfig[] = [
    {
      type: InputType.formitem,
      props: {
        label: '您的名称',
        name: 'name',
        rules: [{ required: true, message: '请填写姓名' }],
        style: {
          width: '100%',
        },
      },
      children: {
        type: InputType.input,
        props: {
          placeholder: '请输入您的名称',
          style: {
            width: '100%',
          },
        },
      },
    },
    {
      type: InputType.formitem,
      props: {
        label: '手机号',
        name: 'phone',
        rules: [{ validator: validatePhone, validateTrigger: 'onBlur' }],
        style: {
          width: '100%',
        },
      },
      children: {
        type: InputType.input,
        props: {
          placeholder: '请输入您的手机号',
          style: {
            width: '100%',
          },
        },
      },
    },
    {
      type: InputType.formitem,
      props: {
        label: '您的邮箱',
        name: 'email',
        rules: [{ validator: validateMail, validateTrigger: 'onBlur' }],
      },
      children: {
        type: InputType.input,
        props: {
          placeholder: '请输入您的邮箱',
        },
      },
    },
    {
      type: InputType.formitem,
      props: {
        label: '请选择您咨询的类型',
        name: 'type',
        rules: [{ required: true, message: '请选择咨询类型' }],
      },
      children: {
        type: InputType.radiogroup,
        props: {
          buttonStyle: 'solid',
          value: radioValue,
          onChange: (e: any) => {
            setRadioValue(e.target.value);
          },
        },
        children: [
          { type: Radio.Button, props: { value: 1 }, children: '购买咨询' },
          {
            type: Radio.Button,
            props: { value: 2 },
            children: '意见&建议',
          },
          { type: Radio.Button, props: { value: 3 }, children: '需求开发' },
          { type: Radio.Button, props: { value: 4 }, children: '其他问题' },
        ],
      },
    },
    {
      type: InputType.formitem,
      props: {
        label: '问题描述',
        name: 'remark',
        rules: [{ required: true, message: '请填写内容' }],
      },
      children: {
        type: InputType.textarea,
        props: {
          placeholder: '问题描述',
        },
      },
    },
  ];

  const submit = async () => {
    setLoading(true);
    try {
      const res = await formRef.current?.validateFields();
      await SaveConcatInfo(res);
      message.success('提交成功');
      formRef.current?.resetFields();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={styles['contact-wrap']}>
      <div className={styles['contact-content-wrap']}>
        <div className={styles['contact-content-title']}>联系我们</div>
        <p className={styles['contact-content-desc']}>
          如果您有任何疑问，建议和需求，都可以给我们发送信息，我们希望收到您的信息，看看有什么可以帮到您。
        </p>
        <div className={styles['code-wrap']}>
          {/*<div className={styles['code-item-wrap']}>
            <div className={styles['code-item-title_wrap']}>专属客服</div>
            <div className={styles['code-item-content_wrap']}>
              <p className={styles['code-item-content_desc']}>
                如有任何问题，请联系专属客服
              </p>
              <img className={styles['code-item-image']}></img>
            </div>
          </div>
          <div className={styles['code-item-wrap']}>
            <div className={styles['code-item-title_wrap']}>技术客服</div>
            <div className={styles['code-item-content_wrap']}>
              <p className={styles['code-item-content_desc']}>
                如有任何问题，请联系专属客服
              </p>
              <img className={styles['code-item-image']}></img>
            </div>
          </div>*/}
          <div className={styles['code-item-wrap']}>
            <div className={styles['code-item-title_wrap']}>官方交流QQ群</div>
            <div className={styles['code-item-content_wrap']}>
              <p className={styles['code-item-content_desc']}>
                如有任何问题，请联系专属客服
              </p>
              <img className={styles['code-item-image']} src={ContactUsQQ}></img>
              Flowable企业级应用
              <br/>
              群号：633168411
            </div>
          </div>

        </div>
        <div className={styles['separation']}>or</div>
        <div style={{ width: '100%' }}>
          <CommonForm
            formConfig={formConfig}
            layout="vertical"
            wrapperCol={{ span: 24 }}
            labelCol={{ span: 24 }}
            ref={formRef}
          />
        </div>
        <Button
          className={styles['contact-submit']}
          onClick={submit}
          loading={loading}
        >
          发送信息
        </Button>
      </div>
    </div>
  );
};
export default ContactUs;
