import { FC } from 'react';

export interface TitleProps {
  title: string;
}
const TitleComponents: FC<TitleProps> = ({ title }) => {
  return (
    <div
      style={{
        width: '100%',
        height: 50,
        borderBottom: '1px solid #D9D9D9',
        color: '#000000',
        paddingLeft: 16,
        fontSize: 16,
        lineHeight: '50px',
      }}
    >
      {title}
    </div>
  );
};
export default TitleComponents;
