import { FC } from 'react';

export interface IconColorProps {
  fill?: string;
}
const Mine: FC<IconColorProps> = ({ fill = '#919398' }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="&#230;&#136;&#145;&#231;&#154;&#132;">
        <path
          id="Vector"
          d="M5.40325 10.7C4.97618 10.6996 4.55321 10.7833 4.15853 10.9465C3.76385 11.1096 3.40519 11.3489 3.10305 11.6507C2.80092 11.9526 2.56123 12.311 2.3977 12.7055C2.23417 13.1 2.15 13.5229 2.15 13.95C2.15 15.7432 3.6074 17.2 5.40315 17.2H12.5967M5.40325 10.7L5.40315 10.8V10.7H5.40325ZM5.40325 10.7H12.5968C14.3926 10.7 15.85 12.1568 15.85 13.95C15.85 14.3771 15.7658 14.7999 15.6023 15.1945C15.4388 15.589 15.1991 15.9474 14.8969 16.2492C14.5948 16.5511 14.2362 16.7904 13.8415 16.9535C13.4468 17.1167 13.0238 17.2004 12.5967 17.2M12.5967 17.2L12.5968 17.1V17.2H12.5967ZM12.597 16.1H12.5968H5.40315C4.21492 16.1 3.25 15.1353 3.25 13.95C3.25 13.6674 3.30571 13.3876 3.41395 13.1265C3.52218 12.8655 3.68082 12.6283 3.88078 12.4286C4.08075 12.229 4.31812 12.0707 4.57932 11.9628C4.84051 11.8549 5.12042 11.7996 5.40301 11.8H5.40315H12.5968C13.7851 11.8 14.75 12.7647 14.75 13.95C14.75 14.2326 14.6943 14.5124 14.5861 14.7735C14.4778 15.0345 14.3192 15.2717 14.1192 15.4713C13.9193 15.671 13.6819 15.8293 13.4207 15.9372C13.1595 16.0451 12.8796 16.1004 12.597 16.1ZM4.85 5.39999C4.85 7.91877 6.68523 9.99999 9 9.99999C11.3148 9.99999 13.15 7.91877 13.15 5.39999C13.15 2.88122 11.3148 0.799994 9 0.799994C6.68523 0.799994 4.85 2.88122 4.85 5.39999ZM12.05 5.39999C12.05 7.35659 10.6602 8.89999 9 8.89999C7.33983 8.89999 5.95 7.35659 5.95 5.39999C5.95 3.4434 7.33983 1.89999 9 1.89999C10.6602 1.89999 12.05 3.4434 12.05 5.39999Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.2"
        />
      </g>
    </svg>
  );
};
export default Mine;
