import { FC } from 'react';
import { IconColorProps } from './mine';

const Order: FC<IconColorProps> = ({ fill = '#919398' }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="&#232;&#174;&#162;&#229;&#141;&#149;">
        <path
          id="Vector"
          d="M14.0635 16.3128H3.93722C3.00592 16.3128 2.25 15.5569 2.25 14.6256V3.37746C2.25 2.44617 3.00592 1.69025 3.93722 1.69025H14.0605C14.9918 1.69025 15.7477 2.44617 15.7477 3.37746V14.6256C15.7508 15.5538 14.9918 16.3128 14.0635 16.3128ZM3.93722 2.81203C3.6288 2.81203 3.37481 3.06602 3.37481 3.37444V14.6225C3.37481 14.934 3.6288 15.185 3.93722 15.185H14.0605C14.372 15.185 14.6229 14.934 14.6229 14.6225V3.37444C14.6229 3.06602 14.372 2.81203 14.0605 2.81203H3.93722Z"
          fill={fill}
        />
        <path
          id="Vector_2"
          d="M11.2661 9.57601H5.0585C4.74706 9.57601 4.49609 9.32505 4.49609 9.01361C4.49609 8.70217 4.74706 8.4512 5.0585 8.4512H11.2661C11.5776 8.4512 11.8285 8.70217 11.8285 9.01361C11.8285 9.32505 11.5776 9.57601 11.2661 9.57601Z"
          fill={fill}
        />
        <path
          id="Vector_3"
          d="M12.9172 12.9293H5.05557C4.74413 12.9293 4.49316 12.6783 4.49316 12.3669C4.49316 12.0554 4.74413 11.8045 5.05557 11.8045H12.9172C13.2286 11.8045 13.4796 12.0554 13.4796 12.3669C13.4796 12.6783 13.2286 12.9293 12.9172 12.9293Z"
          fill={fill}
        />
        <path
          id="Vector_4"
          d="M4.49609 5.36705C4.49609 5.83269 4.87405 6.21065 5.3397 6.21065C5.80535 6.21065 6.18331 5.83269 6.18331 5.36705C6.18331 4.9014 5.80535 4.52344 5.3397 4.52344C4.87405 4.52344 4.49609 4.9014 4.49609 5.36705Z"
          fill={fill}
        />
        <path
          id="Vector_5"
          d="M8.13965 5.36705C8.13965 5.83269 8.51761 6.21065 8.98326 6.21065C9.4489 6.21065 9.82686 5.83269 9.82686 5.36705C9.82686 4.9014 9.4489 4.52344 8.98326 4.52344C8.51761 4.52344 8.13965 4.9014 8.13965 5.36705Z"
          fill={fill}
        />
        <path
          id="Vector_6"
          d="M11.7988 5.36705C11.7988 5.83269 12.1768 6.21065 12.6424 6.21065C13.1081 6.21065 13.486 5.83269 13.486 5.36705C13.486 4.9014 13.1081 4.52344 12.6424 4.52344C12.1768 4.52344 11.7988 4.9014 11.7988 5.36705Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
export default Order;
