import React, { useRef } from 'react';
import styles from './index.module.less';

function useDrag(updaeColunms: (index: number, val: number) => void) {
  const dragLineRef = useRef<HTMLDivElement>(null);
  const startLineRef = useRef<HTMLDivElement>(null);
  const startLine = (
    <div className={styles['start-line']} ref={startLineRef}></div>
  );
  const refInitData = useRef<{
    tableLeft: number;
    top: number;
    lastLocationX: number;
  }>({
    tableLeft: 0,
    top: 0,
    lastLocationX: 0,
  });
  const minSize = 60;
  const minMove = 5;
  const onStartDrag = (event: React.DragEvent) => {
    event.dataTransfer.effectAllowed = 'none';
    const target = event.target as HTMLElement;
    const tableContaiber = target.closest('.ant-table-container');

    const dataindex = target.getAttribute('dataindex');
    if (dataindex === null) {
      return;
    }
    event.dataTransfer.setData('text/plain', '');
    target.style.opacity = '0';
    if (dragLineRef.current) {
      const parentElement = target.parentElement;
      if (parentElement) {
        const rect = parentElement?.getBoundingClientRect();
        // let height = 0;

        if (tableContaiber) {
          const tableRect = tableContaiber.getBoundingClientRect();
          // height = tableRect.bottom - tableRect.y;
          // dragLineRef.current.style.height = `${height}px`;
          // dragLineRef.current.style.top = `${rect.y}px`;
          dragLineRef.current.style.display = 'block';
          if (startLineRef.current) {
            startLineRef.current.style.left = `${rect.x - tableRect.x}px`;
            // startLineRef.current.style.top = `${rect.y}px`;
            // startLineRef.current.style.height = `${height}px`;
            startLineRef.current.style.display = 'block';
          }
          refInitData.current = {
            tableLeft: tableRect.x || 0,
            top: target?.getBoundingClientRect().y || 0,
            lastLocationX: rect.left,
          };
        }
      }
    }
  };
  const onEndDrag = (event: React.DragEvent) => {
    if (dragLineRef.current && startLineRef.current) {
      const end = dragLineRef.current.style.left;
      const start = startLineRef.current.style.left;
      const left = parseInt(end) - parseInt(start);
      const target = event.target as HTMLElement;
      target.style.opacity = '1';
      const dataindex = target.getAttribute('dataindex');
      if (startLineRef.current) {
        startLineRef.current.style.display = 'none';
      }
      if (dragLineRef.current) {
        dragLineRef.current.style.display = 'none';
      }
      if (Math.abs(left) > minMove) {
        updaeColunms?.(dataindex !== null ? Number(dataindex) : 0, left);
      }
    }
  };
  const onDrag = (event: React.DragEvent) => {
    const clientX = event.clientX;
    if (startLineRef.current && dragLineRef.current) {
      const end = clientX - refInitData.current.tableLeft;
      const start = startLineRef.current.style.left;
      const gap = end - parseInt(start);
      if (dragLineRef.current && gap > minSize) {
        dragLineRef.current.style.left = `${end}px`;
      }
    }
  };
  const dragEle = (
    <div
      draggable
      onDrag={onDrag}
      onDragStart={onStartDrag}
      onDragEnd={onEndDrag}
      className={styles['react-resizable-handle']}
    ></div>
  );
  const dragLine = (
    <div className={styles['drag-line']} ref={dragLineRef}></div>
  );

  return { startLine, dragEle, dragLine };
}

export default useDrag;
