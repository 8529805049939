import { useMemo } from 'react';
import styles from './index.module.less';
import { ArrowRightOutlined } from '@ant-design/icons';
const Thanks = () => {
  const items = [
    'vue',
    'vue.Draggable',
    'element',
    'ace',
    'vue-i18n',
    'lodash',
    'ant-design-vue',
  ];
  const Items = useMemo(() => {
    return items.map((item, index) => (
      <li key={index} className={styles['thanks-content-item']}>
        <a href="">{item}</a>
        <ArrowRightOutlined
          style={{
            color: '#14BD85',
            fontSize: 8.5,
            transform: `rotate(-45deg)`,
            marginTop: 3,
            marginLeft: 5,
          }}
        />
      </li>
    ));
  }, [items]);
  return (
    <div className={styles['thanks-wrap']}>
      <div className={styles['thanks-content-wrap']}>
        <div className={styles['thanks-content-title']}>鸣谢！</div>
        <div className={styles['thanks-content-desc']}>
          BpmPort 离不开第三方开源组件的帮助，特别鸣谢下面第三方组件：
        </div>
        <ul>{Items}</ul>
      </div>
      <div className={styles['vercode-wrap']}>
        <div className={styles['vercode-content-wrap']}>
          <img
            src=""
            alt=""
            style={{
              width: 152,
              height: 152,
              background: '#D9D9D9',
              marginBottom: 11,
            }}
          />
          <p style={{ fontSize: 12, color: '#666666' }}>
            关注微信公众号，获取最新动态
          </p>
        </div>
      </div>
    </div>
  );
};
export default Thanks;
