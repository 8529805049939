import { HomeOutlined } from '@ant-design/icons';
import { ValiateIntType, ModelOptions, ModelObj } from './type';
import modal from 'antd/lib/modal';
import { Key } from 'react';

export const setLocalStorage = (key: string, data: string | object) => {
  if (data === null) return;
  if (typeof data === 'object') {
    window.localStorage.setItem(key, JSON.stringify(data));
  } else {
    window.localStorage.setItem(key, data);
  }
};
export const getLocalStorage = (key: string) => {
  return window.localStorage.getItem(key);
};
export const clearLocalstorage = (key: string) => {
  window.localStorage.removeItem(key);
};
export const valiateNumberRule: ValiateIntType<string | number> = (
  value,
  min,
  max,
) => {
  return isNaN(Number(value)) ||
    Number(value) > max ||
    Number(value) < min ||
    Number(value) % 1 !== 0
    ? Promise.reject(`请输入${min}-${max}之间的整数！`)
    : Promise.resolve();
};
export const deleteChildren = (arr: any[]) => {
  return arr.map((item) => {
    if (!item.children || (item.children && item.children.length === 0)) {
      delete item.children;
    } else if (item.children && item.children.length) {
      deleteChildren(item.children);
    }
    return item;
  });
};
/**
 *
 * @param arr 一维数组
 * @param noChild 如在child没有的情况下不需要children属性传true
 * @returns
 */
export const arrayTransformToTree = (
  arr: any[],
  noChild = false,
  parentId = 'pid',
  id = 'id',
): any[] => {
  if (!Array.isArray(arr)) return [];
  const compositionObj: any = {};
  const tree = arr.reduce((prev, item) => {
    if (!item[parentId]) {
      if (item.children) {
        if (compositionObj[item[id]]) {
          compositionObj[item[id]].unshift(...item.children);
        } else {
          compositionObj[item[id]] = item.children;
        }
      } else {
        compositionObj[item[id]] = compositionObj[item[id]] || [];
      }
      item.children = compositionObj[item[id]];
      prev.push(item);
    } else {
      if (item.children) {
        if (compositionObj[item[id]]) {
          compositionObj[item[id]].unshift(...item.children);
        } else {
          compositionObj[item[id]] = item.children;
        }
      } else {
        compositionObj[item[id]] = compositionObj[item[id]] || [];
      }
      item.children = compositionObj[item[id]];
      compositionObj[item[parentId]] = compositionObj[item[parentId]] || [];
      compositionObj[item[parentId]].push(item);
    }
    return prev;
  }, []);

  if (noChild) {
    const noChildTree = deleteChildren(tree);
    return noChildTree;
  }
  console.log(compositionObj, '========');
  return tree;
};
/**
 *
 * @param content 要显示的文本内容
 * @param onOk 点击确定的事件
 * @param options { 弹框的传递对象
 *  @param okText 确定按钮的内容
 *  @param cancelText 取消按钮的内容
 *  @param type 图标的类型
 *  @param title 标题的内容
 * }
 * @returns
 */
export const showWarnModal = (
  content: string,
  onOk: (data?: any) => void,
  options?: ModelOptions,
) => {
  let staticOpt = {
    type: 'confirm',
    title: '',
    okText: '确认',
    cancelText: '取消',
    ...options,
  };
  let obj: ModelObj = {
    icon: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={`${require(`@/assets/images/${
            staticOpt.type === 'confirm'
              ? 'warn_model.png'
              : staticOpt.type + '_model.png'
          }`)}`}
          className="icon"
        />
        <div className="model--title">{staticOpt.title}</div>
      </div>
    ),
    className: 'title',
    content: <p className="content">{content}</p>,
    onOk,
    okText: staticOpt.okText,
    cancelText: staticOpt.cancelText,
  };
  return staticOpt.type === 'confirm' ? modal.confirm(obj) : modal.success(obj);
};
/**
 *返回级联选择框需要的数据结构
 * @param data 树形结构
 * @param lableKey 需要绑定的label字段
 * @param valueKey 需要绑定的value字段
 * @returns
 */
export const genCascaderChildren = (
  data: any[],
  lableKey = 'cname',
  valueKey = 'id',
): any[] => {
  return data.map((item) => {
    return {
      value: item[valueKey],
      label: item[lableKey],
      children:
        (item.children &&
          item.children.length &&
          genCascaderChildren(item.children, lableKey, valueKey)) ||
        null,
    };
  });
};
/**
 * 返回tree控件需要的结构
 * @param data 树形结构
 * @param titleKey
 * @param key
 * @returns
 */
export const genTree = (
  data: any[],
  titleKey = 'cname',
  key = 'id',
  standbyTitle = 'name',
  useIcon = false,
): any[] => {
  return data.map((item) => {
    return {
      title: item[titleKey] || item[standbyTitle],
      key: item[key],
      isCompany: !item[standbyTitle],
      companyId: item.companyId || item.id,
      icon: useIcon && !item[standbyTitle] && <HomeOutlined />,
      children:
        (item.children &&
          item.children.length &&
          genTree(item.children, titleKey, key, standbyTitle, useIcon)) ||
        null,
    };
  });
};
/**
 *
 * @param tree 展开的一维数组
 * @param value 搜索内容
 * @returns 需要展开的key
 */
export const filterCompanyAndDepSearchTree = (
  tree: any[],
  value: string | undefined,
): Key[] => {
  const result = tree
    .filter((item) => {
      return (item.cname || item.name).indexOf(value) > -1;
    })
    .reduce(
      (prev, cur) =>
        cur
          ? cur.pid
            ? [...prev, cur.pid]
            : cur.companyId
            ? [...prev, cur.companyId]
            : prev
          : prev,
      [],
    );
  const setRes: Key[] = Array.from(new Set(result));
  return setRes;
};

export const hasChildRoute = (routes: string[], curPath: string): boolean => {
  console.log(routes, curPath);
  console.log(routes.findIndex((item) => item.indexOf(curPath) > -1));
  return routes.findIndex((item) => curPath.indexOf(item) > -1) > -1;
};
/**
 * 获取url参数
 */
export const getQueryVariable = (variable: string) => {
  let query = window.location.search.substring(1);
  let vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    let params = vars[i].split('=');
    if (params[0] === variable) {
      return params[1];
    }
  }
  return false;
};
