/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createElement,
  ReactNode,
  forwardRef,
  ForwardRefRenderFunction,
  memo,
  isValidElement,
} from 'react';
import {
  Form,
  Input,
  InputNumber,
  Button,
  FormInstance,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Select,
  Cascader,
  Upload,
  Checkbox,
  Radio,
  Switch,
} from 'antd';
import { FormDefaultProps, FormConfig, InputType } from './form.types';
import { PlusOutlined } from '@ant-design/icons';
import pathEnv from '../../../env';
import { getLocalStorage } from '@/util/util';
import styles from './index.module.less';
import CommonUpload from '../common-upload';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
import CommonButton from "@/components/common-button";

const formConfig = {
  [InputType.formitem]: { type: Form.Item, defaultProps: {} },
  [InputType.div]: { type: 'div', defaultProps: {} },
  [InputType.input]: { type: Input, defaultProps: { allowClear: true } },
  [InputType.inputnumber]: {
    type: InputNumber,
    defaultProps: {},
  },
  [InputType.button]: { type: Button, defaultProps: {} },
  [InputType.row]: { type: Row, defaultProps: {} },
  [InputType.col]: { type: Col, defaultProps: {} },
  [InputType.datepicker]: { type: DatePicker, defaultProps: {} },
  [InputType.timepicker]: { type: TimePicker, defaultProps: {} },
  [InputType.select]: { type: Select, defaultProps: { allowClear: true } },
  [InputType.textarea]: { type: Input.TextArea, defaultProps: {} },
  [InputType.password]: { type: Input.Password, defaultProps: {} },
  [InputType.upload]: { type: Upload.Dragger, defaultProps: {} },
  [InputType.uploading]: { type: CommonUpload, defaultProps: {} },
  [InputType.checkbox]: { type: Checkbox.Group, defaultProps: {} },
  [InputType.rangepicker]: { type: DatePicker.RangePicker, defaultProps: {} },
  [InputType.radio]: { type: Radio, defaultProps: {} },
  [InputType.radiogroup]: { type: Radio.Group, defaultProps: {} },
  [InputType.switch]: { type: Switch, defaultProps: {} },
  [InputType.reactNode]: { type: 'div', defaultProps: {} },
  [InputType.cascader]: { type: Cascader, defaultProps: {} },
};
/**
 *
 * @param props
 * @param ref
 * @returns
 */
const RefWrapperForm: ForwardRefRenderFunction<
  FormInstance,
  FormDefaultProps
> = (props, ref) => {
  const {
    formConfig: formConfigProps,
    useRow,
    rowConfig,
    colConfig,
    ...resetProps
  } = props;
  const getConfig = (
    type: InputType,
  ): {
    type: any;
    defaultConfig: Record<string, any>;
  } => {
    return {
      type: formConfig[type].type,
      defaultConfig: formConfig[type].defaultProps,
    };
  };
  const genElement = (
    type: InputType | string,
    elprops: any,
    key?: string | number,
    children?: FormConfig[] | FormConfig | string,
  ): ReactNode => {
    // dom就直接返回child
    if (type === InputType.reactNode && isValidElement(children)) {
      return children;
    }
    const elementType =
      type in InputType ? getConfig(type as InputType).type : type;
    const defaultProps =
      type in InputType ? getConfig(type as InputType).defaultConfig : {};
    if (type === InputType.select) {
      const selectElement = createElement(
        elementType,
        elprops ? { ...elprops } : {},
        children &&
          (children as any[]).map((item: any) => {
            return genElement(
              item.type,
              item.props,
              item.props.value,
              item.props.children,
            );
          }),
      );
      return selectElement;
    }
    if (type === 'Cascader') {
      return createElement(Cascader, elprops ? elprops : {});
    }
    if (type === 'uploading') {
      return createElement(CommonUpload, elprops ? elprops : {});
    }
    if (type === 'button') {
      return createElement(CommonButton, elprops ? elprops : {});
    }
    if (type === 'upload') {
      const token = getLocalStorage('external-token');
      return createElement(
        Upload.Dragger,
        elprops
          ? {
              ...elprops,
              action: `${pathEnv}/milk-card/api/milk-card/card/file/upload`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          : {},
        props.uploadChildren
          ? props.uploadChildren
          : [
              <PlusOutlined style={{ fontSize: 50, color: '#555' }} key="1" />,
              <p style={{ marginTop: 30 }} key="2">
                点击上传图片或者拖拽图片至区域内
              </p>,
              <p style={{ fontSize: 12, color: '#999999' }} key="3">
                小于2m，JPG或PNG格式，建议像素638 X 334或相同比例
              </p>,
            ],
      );
    }
    const element = createElement(
      elementType,
      {
        key,
        ...defaultProps,
        ...elprops,
      },
      children &&
        (Array.isArray(children)
          ? children.map(
              (item, index) =>
                item &&
                genElement(
                  item.type,
                  item.props,
                  item.props && item.props.name ? item.props.name : index,
                  item.children,
                ),
            )
          : typeof children === 'string'
          ? children
          : genElement(
              children.type,
              children?.props,
              undefined,
              children?.children,
            )),
    );
    return element;
  };
  return (
    <ConfigProvider locale={zhCN}>
      <Form
        {...resetProps}
        className={`${
          props.customLayout ? styles['custom-layout'] : undefined
        } ${props.className}`}
        layout={props.layout}
        wrapperCol={props.wrapperCol || { span: 16 }}
        labelCol={props.labelCol || { span: 6 }}
        ref={ref}
        initialValues={props.initialValues}
        onValuesChange={props.onValuesChange}
        // labelAlign={labelAlign}
      >
        <>
          {useRow ? (
            <Row {...rowConfig}>
              {formConfigProps.map((item: FormConfig, index: number) => {
                return (
                  <Col {...colConfig} key={index}>
                    {item &&
                      genElement(
                        item.type,
                        item.props,
                        item.props && item.props.name ? item.props.name : index,
                        item.children,
                      )}
                  </Col>
                );
              })}
            </Row>
          ) : (
            formConfigProps.map((item: FormConfig, index: number) => {
              return (
                item &&
                genElement(
                  item.type,
                  item.props,
                  item.props && item.props.name ? item.props.name : index,
                  item.children,
                )
              );
            })
          )}
          {props.children}
        </>
      </Form>
    </ConfigProvider>
  );
};
const CommonForm = memo(forwardRef(RefWrapperForm));
// RefWrapperForm.defaultProps = {
//   useRow: false,
//   labelCol: { span: 8 },
//   wrapperCol: { span: 14 },
// };
export default CommonForm;
