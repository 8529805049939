import { FC } from 'react';
import styles from './index.module.less';
import QqQrCode from '@/assets/images/qq-qr-code.png';
import {notification, Space, Row, Col, Flex} from 'antd';
import { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom';


// const [ api, contextHolder] = notification.useNotification();
const showNotation = ()=>{
  notification.open({
    message: 'Tips',
    placement: 'bottomLeft',
    duration: null,
    description:<div>
      <p style={{marginBottom: '8px'}}>
        <span>(推荐) 企业级 流程引擎 现已发售，采用SpringBoot + Flowable + Vue3 + bpmnJS</span>
        <br/>
        <a href="https://admin.bpmport.com" target="_blank">企业版预览</a>
      </p>
      <p style={{marginBottom: '8px'}}>
        <Space>
          <span>如需了解or购买企业级可</span>
          <a href="//www.bpmport.com/about">联系我们</a>
        </Space>
      </p>
      <p style={{marginBottom: '8px'}}>
        <Space size={30}>
          <a href="//www.bpmport.com" target="_blank">官网</a>
          <a href="//www.bpmport.com/bpmn/docs/" target="_blank">BPMN文档</a>
        </Space>
      </p>
    </div>,
    onClick: () => {
      console.log('Notification Clicked!');
    },
  });
  // api.info({
  //   message: `Notification`,
  //   description: <div>
  //     aaa
  //   </div>,
  //   placement: 'bottomLeft',
  // });
}

interface FooterProps {
  show?: boolean;
}
const Footer: FC<FooterProps> = ({ show = true }) => {
  const renderRef = useRef(true)
  const location = useLocation();

  useEffect(() => {
    // 重要!!!
    if (!renderRef.current) {
      renderRef.current = false
      return
    }
    if (location.pathname === '/' || location.pathname === ''){
      showNotation();
    }
  }, []);

  return (
    <div className={styles['footer-wrap']}>
      {show && (
        <div className={styles['footer-content-wrap']}>
          <ul>
            <li className={styles['footer-item-title']}>链接</li>
            <li className={styles['footer-item']}><a href={"https://github.com/flowable"} target={"_blank"}>Flowable</a></li>
            <li className={styles['footer-item']}><a href={'//www.bpmport.com/bpmn/docs'} target={"_blank"}>使用文档</a></li>
            <li className={styles['footer-item']}><a href={"https://github.com/ecnice/flow"} target={"_blank"}>Github</a></li>
            <li className={styles['footer-item']}><a href={"https://gitee.com/lwj/flow"} target={"_blank"}>Gitee</a></li>
            <li className={styles['footer-item']}><a href={"http://ccflow.org/"} target={"_blank"}>驰骋低代码</a></li>
            <li className={styles['footer-item']}><a href={'//www.orangeforms.com'} target={"_blank"}>橙单免费代码生成工具</a></li>
          </ul>
          <ul>
            <li className={styles['footer-item-title']}>商业授权</li>
            <li className={styles['footer-item']}><a href={"/terms-service"} target={"_blank"}>授权申请</a></li>
            <li className={styles['footer-item']}><a href={"/products"} target={"_blank"}>产品</a></li>
          </ul>
          <ul>
            <li className={styles['footer-item-title']}>联系我们</li>

            <img src={QqQrCode} className={styles['footer-img']}></img>

            <li className={styles['footer-item-qrcode']}>
              添加QQ群，了解产品动态
            </li>
          </ul>
        </div>
      )}
      <div className={styles['footer-copyright']}>
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={8} xl={6}>
            Copyright © 2024-present FlowablePlus流程引擎
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={6}>
            深圳市龙岗区龙岗街道金汐府2栋A座2单元2401
          </Col>
          <Col xs={24} sm={24} md={24} lg={4} xl={3}>
            <a href={"https://beian.miit.gov.cn/#/Integrated/index"} target={"_blank"}>粤ICP备20002509号-1</a>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Footer;
