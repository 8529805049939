import React, {useState, useRef, useEffect} from 'react';

interface TimeViewProps {
  h: string; // 小时
  m: string; // 分钟
  s: string; // 秒
}

type TCountDownWrapper = {
  expire: number; //过期时间
  showDomStruct?: boolean;  //是否显示
};


const CountDownWrapper: React.FC<TCountDownWrapper> = (props) => {
  const countDownTimer = useRef<NodeJS.Timeout>(); // 倒计时标记
  const [timeView, setTimeView] = useState<TimeViewProps | null>(null); // 倒计时显示

  // 倒计时函数
  const countDown = () => {
    const nowTime = +new Date(); //获取當前时间的时间戳（单位毫秒）
    const times = parseInt(`${(props.expire - nowTime) / 1000}`); //把剩余时间毫秒数转化为秒
    const h = parseInt(`${(times / 60 / 60) % 24}`); //计算小时数 转化为整数
    const m = parseInt(`${(times / 60) % 60}`); //计算分钟数 转化为整数
    const s = parseInt(`${times % 60}`); //计算描述 转化为整数

    //设置时间格式
    setTimeView({
      h: h < 10 ? `0${h}` : `${h}`,
      m: m < 10 ? `0${m}` : `${m}`,
      s: s < 10 ? `0${s}` : `${s}`,
    });

    //时间判断
    if (times <= 0) {
      clearTimeout(countDownTimer.current);
      setTimeView({h: '00', m: '00', s: '00'});
    } else {
      countDownTimer.current = setTimeout(() => {
        countDown();
      }, 1000);
    }
  };

  useEffect(() => {
    if (props.expire) {
      countDown();
    } else {
      setTimeView({h: '00', m: '00', s: '00'});
    }
    return () => {
      clearTimeout(countDownTimer.current);
    };
  }, []);


  return (
    <>
      {props.showDomStruct ?? true ? (
        <>
          {timeView?.h}:{timeView?.m}:{timeView?.s}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default CountDownWrapper;
