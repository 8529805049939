import { FC } from 'react';
import { IconColorProps } from './mine';

const Buy: FC<IconColorProps> = ({ fill = '#919398' }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="&#232;&#180;&#173;&#228;&#185;&#176;">
        <path
          id="Vector"
          d="M15.5354 5.36789H2.46384C2.29599 5.36787 2.13001 5.40305 1.97661 5.47118C1.82321 5.53931 1.6858 5.63886 1.57328 5.7634C1.46075 5.88794 1.37561 6.03471 1.32334 6.19421C1.27107 6.35371 1.25284 6.5224 1.26984 6.68939L2.14974 15.3294C2.17984 15.6251 2.31862 15.8991 2.53921 16.0984C2.7598 16.2976 3.04649 16.4079 3.34374 16.4079H14.6558C14.9531 16.4079 15.2398 16.2976 15.4604 16.0984C15.681 15.8991 15.8197 15.6251 15.8498 15.3294L16.7294 6.68939C16.7464 6.5224 16.7282 6.35371 16.6759 6.19421C16.6237 6.03471 16.5385 5.88794 16.426 5.7634C16.3135 5.63886 16.1761 5.53931 16.0227 5.47118C15.8693 5.40305 15.7033 5.36787 15.5354 5.36789ZM2.46384 6.56789H15.5354L14.6558 15.2079H3.34344L2.46384 6.56789Z"
          fill={fill}
        />
        <path
          id="Vector_2"
          d="M10.6795 1.5C11.1452 1.49998 11.5928 1.68047 11.9283 2.00354C12.2637 2.32661 12.4609 2.76711 12.4783 3.2325L12.4795 3.3V7.3155C12.4792 7.47049 12.4188 7.61932 12.3112 7.73082C12.2035 7.84232 12.0569 7.90784 11.9021 7.91366C11.7472 7.91948 11.5961 7.86514 11.4803 7.76202C11.3646 7.65891 11.2933 7.51502 11.2813 7.3605L11.2795 7.3155V3.3C11.2795 3.14868 11.2223 3.00296 11.1194 2.89203C11.0165 2.7811 10.8754 2.71315 10.7245 2.7018L10.6795 2.7H7.31953C7.16816 2.70003 7.0224 2.75728 6.91146 2.86026C6.80052 2.96324 6.73261 3.10435 6.72133 3.2553L6.71953 3.3003V7.3155C6.71916 7.47049 6.65884 7.61932 6.55119 7.73082C6.44355 7.84232 6.29693 7.90784 6.14205 7.91366C5.98717 7.91948 5.83606 7.86514 5.72035 7.76202C5.60464 7.65891 5.53332 7.51502 5.52133 7.3605L5.51953 7.3155V3.3C5.51959 2.83434 5.70011 2.38682 6.02318 2.05145C6.34624 1.71608 6.7867 1.51896 7.25203 1.5015L7.31953 1.5H10.6795Z"
          fill={fill}
        />
        <path
          id="Vector_3"
          d="M12.3002 12.9V13.5C12.3002 13.6591 12.237 13.8117 12.1245 13.9243C12.0119 14.0368 11.8593 14.1 11.7002 14.1H6.3002C6.14107 14.1 5.98845 14.0368 5.87593 13.9243C5.76341 13.8117 5.7002 13.6591 5.7002 13.5V12.9H12.3002Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
export default Buy;
