import styles from './index.module.less';
import {Key} from 'react';

export interface ColorConfigProps {
  [key: number]: string;
}
export interface ListProps {
  amount: number;
  codeUrl: string;
  id: Key;
  introduce: string;
  productName: string;
  productNo: string;
}
const TermsService = () => {

  return (
    <div className={styles['terms-service-wrap']}>
      <div className={styles['commercial-license-title']}>平台用户服务条款与隐私政策</div>

      <div className={styles['commercial-license-problem_wrap']}>
        <p className={styles['commercial-license-problem_desc']}>
          欢迎您来到 FlowablePlus流程引擎 平台（以下简称“本站”）。请您仔细阅读以下条款，如果您对本协议的任何条款表示异议，您可以选择不进入本站。 当您注册成功，无论是进入本站，还是在本站平台上发布任何内容（即「内容」），均意味着您（即「用户」）完全接受本协议项下的全部条款。
        </p>
        <div className={styles['commercial-license-problem_subtitle']}>
          一、使用规则
        </div>
        <p className={styles['commercial-license-problem_desc']}>
          1、用户注册成功后，本站将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管， 用户不应将账号或密码告知第三方，或者将其帐号、密码转让、出借予他人使用或与第三方共用。 如用户发现其帐号遭他人非法使用，应立即通知本站，因黑客行为或用户的保管疏忽等非本站原因导致帐号、密码遭他人非法使用或无法正常使用的， 本站不承担任何责任；用户应当对以其用户帐号进行的所有活动和事件负法律责任。
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          2、用户应提供及时、详尽及准确的个人资料，并不断更新注册资料，用户须对在本站的注册信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人； 不得利用他人的名义发布任何信息；不得恶意使用注册帐号导致其他用户误认；否则本站有权立即停止提供服务， 收回其帐号并由用户独自承担由此而产生的一切法律责任。
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          3、用户直接或通过各类方式（如 RSS 源和站外 API 引用等）间接使用本站服务和数据的行为，都将被视作已无条件接受本协议全部内容； 若用户对本协议的任何条款存在异议，请停止使用本站所提供的全部服务。
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          4、本站是一个信息分享、传播及获取的平台，用户通过本站发表的信息为公开的信息，其他第三方均可以通过本站获取用户发表的信息， 用户对任何信息的发表即认可该信息为公开的信息，并单独对此行为承担法律责任； 任何用户不愿被其他第三人获知的信息都不应该在本站上进行发表。
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          5、用户承诺不得以任何方式利用本站直接或间接从事违反中国法律以及社会公德的行为，本站有权对违反上述承诺的内容予以删除。
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          6、用户不得利用本站服务制作、上载、复制、发布、传播或者转载如下内容：
          <br/>
          <pre>
          （1）反对宪法所确定的基本原则的；<br/>
          （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br/>
          （3）损害国家荣誉和利益的；<br/>
          （4）煽动民族仇恨、民族歧视，破坏民族团结的；<br/>
          （5）破坏国家宗教政策，宣扬邪教和封建迷信的；<br/>
          （6）散布谣言，扰乱社会秩序，破坏社会稳定的；<br/>
          （7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br/>
          （8）侮辱或者诽谤他人，侵害他人合法权益的；<br/>
          （9）违反中国法律、法规、规章、条例以及任何具有法律效力之规范的.<br/>
          </pre>
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          7、本站有权对用户使用本站的情况进行审查和监督，如用户在使用本站时违反任何上述规定， 本站或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容、暂停或终止用户使用本站的权利）， 因此产生的全部责任及损失由用户承担。
        </p>

        <div className={styles['commercial-license-problem_subtitle']}>
          二、知识产权
        </div>
        <p className={styles['commercial-license-problem_desc']}>
          本站是一个信息获取、分享及传播的平台，我们尊重和鼓励本站用户创作的内容，认识到保护知识产权对本站生存与发展的重要性， 承诺将保护知识产权作为本站运营的基本原则之一。
        </p>

        <p className={styles['commercial-license-problem_desc']}>
          1、用户在本站发表的全部原创内容（包括但不仅限于回答、文章和评论）， 著作权均归用户本人所有。用户可授权第三方以任何方式使用，不需要得到本站的同意。
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          2、本站提供的网络服务中包含的标识、版面设计、排版方式、文本、图片、图形等均受著作权法、商标法及其它法律保护， 未经相关权利人（含本站及其他原始权利人）同意，上述内容均不得在任何平台被直接或间接发布、使用、出于发布或使用目的的改写或再发行， 或以其他任何法律允许外的方式使用。
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          3、为了促进知识的分享和传播，用户将其在本站发表的全部内容，授予本站免费的、不可撤销的、非独家、永久的、全球范围内的使用许可， 本站有权将该内容用于本站各种形态的产品和服务上，包括但不限于网站以及发表的应用或其他互联网产品。
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          4、除原作者另有说明或法律规定不得转载的情况外，若出于非商业目的，将用户在本站发表的内容转载在本站之外的地方， 应当在作品的正文开头的显著位置注明原作者姓名（或原作者在本站使用的帐号名称），给出原始链接，注明「发表于本站」， 并不得对作品进行修改演绎。若需要对作品进行修改，或用于商业目的，应当联系用户获得单独授权，按照用户规定的方式使用该内容。
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          5、在本站上传或发表的内容，用户应保证其为相关权利所有人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益。 如果第三方提出关于著作权等合法权益的异议，本站有权删除相关内容，且有权追究用户的法律责任。给本站或任何第三方造成损失的，用户应负责全额赔偿。
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          6、如果任何第三方侵犯了本站用户相关的权利，用户同意授权本站或其指定的代理人代表本站自身或用户对该第三方提出警告、投诉、发起行政执法、诉讼、进行上诉， 或谈判和解，并且用户同意在本站认为必要的情况下参与共同维权。
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          7、本站有权但无义务对用户发布的内容进行审核，有权根据相关证据结合《侵权责任法》、《信息网络传播权保护条例》等法律法规及本站社区指导原则对侵权信息进行处理。
        </p>

        <div className={styles['commercial-license-problem_subtitle']}>
          三、个人隐私
        </div>

        <p className={styles['commercial-license-problem_desc']}>
          1、保护用户个人信息是本站的一项基本原则，本公司将会采取合理的措施保护用户的个人信息。当您访问本站的时候可能被要求提供您个人的基本资料(如用户名、手机号、邮箱等)，您可以自行选择是否提供。
          对于您提供的非公开的个人信息，本站不会将其提供给第三方，但下述情况除外：
          <pre>
            （1）司法机关、行政机关或其他有关部门要求披露的；<br/>
            （2）为维护本站及维护团队的合法权益、社会公众利益需要披露的；<br/>
            （3）本站与第三方合作向用户提供相关网络服务需要向第三方披露的；<br/>
            （4）依照法律法规要求披露的。
          </pre>
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          2、以下情形造成的任何个人资料的泄露、丢失、被盗用、被篡改等，本站不承担任何责任：
          <pre>
            （1）由黑客攻击、计算机病毒侵入或发作、政府管制等本站不能控制的原因或不可抗力而造成的暂时性关闭等影响网站正常运营的事件所造成的；<br/>
            （2）用户将个人密码、账号告知他人或与他人共享注册帐号所造成的；<br/>
            （3）与本站链接的任何其它网站造成的。<br/>

          </pre>

        </p>

        <div className={styles['commercial-license-problem_subtitle']}>
          四、侵权处理
        </div>


        <p className={styles['commercial-license-problem_desc']}>
          用户在本站发表的内容仅表明其个人的立场和观点，并不代表本站的立场或观点。 如果个人或企业发现本站存在侵犯自身合法权益的内容，可以先尝试与作者取得联系， 通过沟通协商解决问题。如您无法联系到作者，或无法通过与作者沟通解决问题，您可通过向本站平台进行投诉。 本站有权直接采取包括但不限于更改或删除用户张贴的内容、暂停或终止用户使用本站的权利等一切措施， 因此产生的全部责任及损失由用户承担。

        </p>
        <p className={styles['commercial-license-problem_desc']}>
          不论本侵权举报内容是否完全属实，用户将承担由此产生的一切法律责任， 并承担和赔偿本站因根据投诉人的通知书对相关帐号的处理而造成的任何损失， 包括但不限于本站因向被投诉方赔偿而产生的损失及本站名誉、商誉损害等。
        </p>

        <div className={styles['commercial-license-problem_subtitle']}>
          五、免责申明
        </div>
        <p className={styles['commercial-license-problem_desc']}>
          1、本站不能对用户发表的回答或评论的正确性进行保证。
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          2、用户在本站发表的内容仅表明其个人的立场和观点，并不代表本站的立场或观点。 作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。 本站不承担任何法律及连带责任。
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          3、本站不保证网络服务一定能满足用户的要求，也不保证网络服务不会中断， 对网络服务的及时性、安全性、准确性也都不作保证。
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          4、对于因不可抗力或本站不能控制的原因造成的网络服务中断或其它缺陷， 本站不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          5、由于您授权第三方访问/使用本站所导致的纠纷或损失，应由您自行承担。
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          6、您应加强信息安全及个人信息的保护意识，注意密码保护，以免遭受损失。
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          7、平台发现用户违反互联网信息服务管理相关规定，有权停止传输信息，有权依法采取消除、限制功能、关闭账户等处置措施。
        </p>

          <div className={styles['commercial-license-problem_subtitle']}>
          六、其他规定
        </div>
        <p className={styles['commercial-license-problem_desc']}>
          1、如本协议中的任何条款因任何原因完全或部分无效或不具有执行力，均不影响本协议其他条款的效力。
        </p>
        <p className={styles['commercial-license-problem_desc']}>
          2、本协议及本协议任何条款内容的最终解释权及修改权归本站所有。 若您对平台及本服务有任何意见，欢迎加入平台管理员群，群号：633168411。
        </p>
        <br/>
      </div>
    </div>
  );
};
export default TermsService;
