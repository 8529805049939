import React, { useEffect, useState } from 'react';

import {useNavigate} from 'react-router';
import styles from "@/views/products-change-record/index.module.less";
// import Texty from "rc-texty";
import {Anchor, Row, Col, Tag, Spin, Empty} from "antd";
import { useLocation } from 'react-router-dom';
import MarkdownRenderer from "@/views/components/markdown";

import {getProductUpdateLogsByProductNo} from "@/api/product";
const ProductsChangeRecord = () => {
  const navigator = useNavigate();
  const topRef = React.useRef<HTMLDivElement>(null);
  const [targetOffset, setTargetOffset] = useState<number>();
  const [changeRecordList, setChangeRecordList] = useState<[]>([]);
  const [anchorItems, setAnchorItems] = useState<[]>([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get('p');

  // 获取日志列表
  const fetchData = async () => {
    setLoading(true);
    try{
      const {data} = await getProductUpdateLogsByProductNo({productId: productId});
      if(data){
        setChangeRecordList(data);
        const anchorItemsList = data.map(item => {
          return {
            key: item.id,
            href: '#part-' + item.id,
            title: item.versionCode,
          }
        });
        setAnchorItems(anchorItemsList);
      }
      setLoading(false);
    }catch (e){
      setLoading(false);
    }
  }

  useEffect( () => {
    setTargetOffset(topRef.current?.clientHeight);
    fetchData();
  }, []);

  const recordListItems = (list) => {
    const records = list.map((item => {
      return (
        <div
          key={item.id}
          className={styles['record-item']}
          id={'part-' + item.id}
        >
          <h3 className={styles['record-title']}>{item.versionCode}</h3>
          <div className={styles['record-date']}>
            <Tag>{item.publishDate}</Tag>
          </div>
          {/*<div className={styles['record-desc']} dangerouslySetInnerHTML={{__html: item.updateContent}}></div>*/}
          <MarkdownRenderer content={item.updateContent} ></MarkdownRenderer>

        </div>
      )
    }));
    return records;
  }

  return (
    <div>
      <div className={styles['product-update-record-banner']} style={{minHeight: '300px' }}>
        <div className={styles['product-banner-content']}>
          {/*<h1>
            <Texty type={"left"} mode={"smooth"}>
              LCP低代码开发平台
            </Texty>
          </h1>
          <br/><br/>
          <h3>
            <Texty type={"left"} mode={"smooth"}>
              新一代业务流程平台，和传统的昨天告别，向规范的未来迈进
            </Texty>
          </h3>*/}
          {/*<div className={styles['product-banner-content-ctrl']}>
            <Space size={50}>
              <Button size={"large"} type={"primary"} color={"red"}>预约演示</Button>
              <Button size={"large"}>预约演示</Button>
            </Space>
          </div>*/}
        </div>
      </div>
      <div className={styles['container-box']}>
        <h3 className={styles['second-title']}>
          产品更新日志
        </h3>

        <Spin spinning={loading} tip={"加载中..."}>
          <div className={styles['record-list']} >
          {changeRecordList.length> 0 ? (
            <Row gutter={[10, 20]}>
              <Col span={20}>
                {recordListItems(changeRecordList)}
              </Col>
              <Col span={4}>
                <Anchor
                  offsetTop={100}
                  targetOffset={targetOffset}
                  items={anchorItems}
                />
              </Col>
            </Row>
          ) : (<Empty />)}
          </div>
        </Spin>
      </div>
    </div>
  );
};
export default ProductsChangeRecord;
