import api from './index';
export interface RegisterProps {
  email: 'string';
  name?: 'string';
  password: 'string';
  phone: 'string';
  userNo: 'string';
}
export type LoginProps = Omit<RegisterProps, 'phone' | 'name' | 'email'>;
export const RegisterApi = (data: RegisterProps) => {
  return api({
    url: '/user/register',
    method: 'POST',
    data,
  });
};

export const ResetPwdApi = (data: RegisterProps) => {
  return api({
    url: '/user/resetPassWord',
    method: 'POST',
    data,
  });
};

export const SendEmailApi = (email: string) => {
/*  return Promise.resolve({
    success: true,
    code: '100',
    data: '',
    msg: '发送成功',
  });*/
  return api({
    url: '/user/sendCodeByEmail',
    method: 'POST',
    params: {email},
  });
};

export const getVerifyCode = () => {
  return api({
    url: '/verify/getCode',
    method: 'GET'
  });
};

export const LoginApi = (data: LoginProps) =>
  api({ url: '/system/login', method: 'POST', data });
export const LogoutApi = () => api({ url: '/system/logout', method: 'POST' });
