import { useNavigate } from 'react-router';
import styles from './index.module.less';

const NotFound = () => {
  const navigator = useNavigate();
  return (
    <div className={styles.content}>
      <h1>404</h1>
      <div
        className="href"
        onClick={() => {
          navigator('/bh/login');
        }}
      >
        返回登录
      </div>
    </div>
  );
};
export default NotFound;
