import styles from './index.module.less';
import TitleComponents from '../components/title';
import { useContext } from 'react';
import { LocalStorageContext } from '@/views/provider/storage-provider';
const PersonalData = () => {
  const LocalContext = useContext(LocalStorageContext);

  return (
    <div className={styles['personal-data-wrap']}>
      <TitleComponents title="我的资料" />
      <div className={styles['personal-data-content_wrap']}>
        <div className={styles['personal-data-item']}>
          账号：&nbsp;&nbsp;{LocalContext?.userNo}
        </div>
        <div className={styles['personal-data-item']}>
          邮箱：&nbsp;&nbsp;{LocalContext?.email}
        </div>
        <div className={styles['personal-data-item']}>
          手机号：&nbsp;&nbsp;{LocalContext?.phone}
        </div>
        <div className={styles['personal-data-item']}>
          注册时间：&nbsp;&nbsp;{LocalContext?.createdTime}
        </div>
        {/* <div className={styles['personal-data-item']}>
          状态：&nbsp;&nbsp;124
        </div> */}
      </div>
    </div>
  );
};
export default PersonalData;
