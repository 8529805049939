import api from '.';

export const getProductList = () =>
  api({ url: '/base/product/getProductList', method: 'POST' });

export const getCurrentDate = () =>
  api({ url: '/base/product/getCurrentDate', method: 'GET' });

/*export const getProductDetailById = (params) => {
  return Promise.resolve({data: {amount: 499, originalPrice: 2999, activityTime: new Date('2024-08-20 23:59:59')}});
}*/

export const getProductDetailById = (params) =>
  api({ url: `/base/product/getProductDetailById/${params.id}`, method: 'GET' });


export const getProductUpdateLogsByProductNo = (params) => {
  return api({
    url: '/base/productVersionUpdateLog/getList',
    method: 'POST',
    params
  });
}

export const getProductUpdateLogsCount = () => {

  return Promise.resolve({
    data: [
      {
        productId: 1,
        logCount: 12,
      },
      {
        productId: 2,
        logCount: 33,
      },
      {
        productId: 3,
        logCount: 39,
      },
    ],
    success: true,
    msg: '获取更新日志统计成功'
  });

  return api({
    url: '/base/productVersionUpdateLog/getProductUpdateLogsCount',
    method: 'GET'
  });
}
