import styles from '../product.module.less';
import {Divider, Space} from 'antd';
import {ProductDetailItem, ProductPrice, BuyButton} from '@/views/products/components/common';
import {useState, useEffect} from "react";
import {getProductDetailById} from "@/api/product";

const ProductItem3 = () => {
  const deadlineTime = new Date('2024-08-20 23:59:59').getTime(); // Dayjs is also OK
  // activityTime
  const [productDetail, setProductDetail]
    = useState({amount: 1999, originalPrice: 5999});

  const [deadline, setDeadline] = useState(deadlineTime)

  const fetchProductDetail = async (params) => {
    try {
      const { data } = await getProductDetailById(params);
      setDeadline(data.activityTime);
      setProductDetail(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchProductDetail({id: 1});
  }, []);

  return (
    <div className={styles['product-card']}>
      <div className={`${styles['product-header']} ${styles['product3']}`}>
          <div className={styles['product-header-title']}>
            <div className={styles['product-header-icon']}></div>
            <h3>Flowable流程设计器</h3>
          </div>
          <div className={styles['product-view-url']}>
            <div>
              <Space size={20}>
                <a href={"https://designer.bpmport.com/designer/"} target={"_blank"}>流程设计</a>
                <a href={"https://designer.bpmport.com/dmn/"} target={"_blank"}>决策设计</a>
              </Space>
            </div>
            <div style={{marginTop: '10px'}}>
              <Space size={20}>
                <a href={"https://designer.bpmport.com/viewer/"} target={"_blank"}>流程渲染</a>
                <a href={"https://designer.bpmport.com/dmnviewer/"} target={"_blank"}>决策渲染</a>
              </Space>
            </div>
          </div>
          <div className={styles['product-header-desc']}>
            深度融合Flowable、优化用户体验
          </div>
        </div>
      <div className={styles['product-price']}>
        <div className={styles['price']}>
          <ProductPrice originPrice={productDetail.originalPrice} currentPrice={productDetail.amount} deadline={deadline}/>
        </div>
        <div className={styles['link']}>
          <BuyButton productNo={"1"}/>
        </div>
      </div>
      <Divider/>
      <div className={styles['detail']}>
        <ul>
          <li>
            <ProductDetailItem>
              支持Flowable
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              支持Vue2.x/Vue3.x
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              提供源码、允许二开
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              常用的组件扩展功能
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              强大的决策设计与渲染
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              开具电子发票
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              企业版BPMN设计器
            </ProductDetailItem>
          </li>
          <li>
            <ProductDetailItem>
              技术支持 7个工作日
            </ProductDetailItem>
          </li>
        </ul>

      </div>
    </div>
  );
};
export default ProductItem3;
