import { Button, Checkbox, Radio } from 'antd';
import styles from './index.module.less';
import { useState } from 'react';
import { useNavigate } from 'react-router';
const Agreement = () => {
  const [radioValue, setRadioValue] = useState(false);
  const navigator = useNavigate();
  return (
    <div className={styles['agreement-wrap']}>
      <div className={styles['agreement-content-wrap']}>
          用户购买授权时（以下称为“授权者”），即意味同意以下协议：<br/>
          <br/>
          1、授权者购买的授权只能用在自己的公司或个人项目中，第三方如需用该授权，需重复购买，否则视为违权。
          <br/><br/>

          2、本授权不能给任何其它主体（企业或组织、子公司、个人或其他独立主体）使用。
          <br/><br/>

          3、授权者不得恶意传播该产品及源码，不得对授权的产品进行二次销售、转卖，创作不易，请务必尊重知识产权。
          <br/><br/>

          4、授权者不得基于授权的产品，包装成一个与授权产品功能类似的程序，参与同类软件产品市场竞争和销售或发布。
          <br/><br/>

          5、本协议中所涉指的产品为保密信息，包括：软件源码、数据、信息、协议、参考资料及功能界面。 授权者除自己合理使用外，不得将授权的产品进行泄露或告知、交付、转移给第三方。一经发现，我们有权收回授权产品和不再提供任何升级服务，并根据产生的后果追究其相应法律责任。
          <br/><br/>

          6、一旦购买成功本平台授权产品，不支持退款。
          <br/><br/>

          7、购买成功后，授权者可对源码进行修改和扩展用于授权的主体或个人使用。

          <br/><br/>
          8、我们有义务和责任在有效时间内（一年）为授权者提供授权版本的产品下载、更新和维护，一旦过期，授权者则无法享有相应权限。
          <br/><br/>
          9、购买授权一年后(以订单支付时间为准)，如果您还需要更新或升级该产品：
          <br/>
          &nbsp;&nbsp;&nbsp;&nbsp;Flowable流程设计器版：每年需重新支付产品总价格的20%升级费。
          <br/>
          &nbsp;&nbsp;&nbsp;&nbsp;FlowablePlus插件版：每年需重新支付产品总价格的20%升级费。
          <br/>
          &nbsp;&nbsp;&nbsp;&nbsp;企业版：根据各自签字的合同协议约定。

          <br/><br/>
          10、本协议及本协议任何条款内容的最终解释权及修改权归深圳途浪科技有限公司所有。


      </div>
      <div style={{textAlign: "left", marginTop: "30px", width: "100%"}}>
        <Checkbox
          checked={radioValue}
          onChange={(e) => {
            console.log(e.target.checked);
            setRadioValue(e.target.checked);
          }}
        >
          同意协议
        </Checkbox>
        <br/>
        <Button
          style={{ marginTop: 24 }}
          className={styles['agreement-pay-btn']}
          type="primary"
          onClick={() => {
            navigator('pay');
            window.scrollTo(0, 0); // 滚动到页面顶部
          }}
          disabled={!radioValue}
        >
          去支付
        </Button>
      </div>

    </div>
  );
};
export default Agreement;
