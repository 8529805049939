import React, { useEffect, useState } from 'react';
import Header, { userInfoProps } from '@/components/header';
import Footer from '@/components/footer';
import { Outlet, useLocation } from 'react-router';
import { getLocalStorage } from '@/util/util';
import LocalStorageProvider from '../provider/storage-provider';
import { Subject } from 'rxjs';
import RightBtn from '@/views/components/right-btn';


export const logoutSubject = new Subject();
const Homepage = () => {
  const location = useLocation();
  const [show, setShow] = useState(true);
  const falseList = ['login', 'register'];
  const [userInfo, setUserInfo] = useState<userInfoProps>(null);

  const getUsrInfo = () => {
    const user = getLocalStorage('userInfo');
    console.log(JSON.parse(user!), '------user');
    if (user) {
      setUserInfo(JSON.parse(user));
    } else {
      setUserInfo(null);
    }
  };
  useEffect(() => {
    getUsrInfo();
  }, []);
  useEffect(() => {
    const subscription = logoutSubject.subscribe(() => {
      getUsrInfo();
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  const updateUser = (data: userInfoProps) => {
    console.log(data, '-------update');
    setUserInfo(data);
  };
  useEffect(() => {
    console.log(location);
    setShow(!falseList.includes(location.pathname));
  }, [location]);
  return (
    <div>
      <LocalStorageProvider value={{ ...userInfo, updateUser }}>
        <Header userInfo={userInfo} />
        <div style={{marginTop: '50px'}}>

        </div>
        <Outlet />
        <Footer show={show} />
        <RightBtn />
      </LocalStorageProvider>
    </div>
  );
};
export default Homepage;
