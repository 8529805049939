import api from './index';

// 图片上传
export const postUploadFile = (data: FormData) => {
  return api({
    url: '/app-order-admin/product/file',
    method: 'POST',
    data,
  });
};
