import axios, { AxiosRequestHeaders, AxiosResponse, Method } from 'axios';
import pathsEnv from '../../env';
import { message } from 'antd';
import { history } from '@/routes/history';
import { clearLocalstorage, getLocalStorage } from '@/util/util';
import { logoutSubject } from '@/views/homepage';
let baseURL = pathsEnv;
export let token: string | null;
const instance = axios.create({

  baseURL: '/bpmn/front/api',
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
  },
});
axios.defaults.withCredentials = true

instance.interceptors.request.use((config) => {
  if (config.url !== '/third-order-agent/agent/user/login') {
    let localToken = getLocalStorage('userInfo');
    if (localToken) {
      token = JSON.parse(localToken).token;
    }
  }
  if (token) {
    config!.headers!.bpmnToken = token;
  }
  return config;
});
instance.interceptors.response.use(
  (res) => {
    if (
      (res.data && res.data.code === '00000')
    ) {
      return Promise.resolve(res.data);
    }else if(
      res.data instanceof Blob ||
      res.data instanceof ArrayBuffer
    ){
      return Promise.resolve(res);
    } else {
      if (
        (res.data && res.data.code === '402') ||
        res.data.code === '401' ||
        res.data.code === '402' ||
        res.data.code === '405' ||
        res.data.code === '50000'
      ) {
        clearLocalstorage('external-token');
        clearLocalstorage('external-userInfo');
        clearLocalstorage('userInfo');
        token = null;
        logoutSubject.next(1);
        history.replace('/login');
      }
      console.log('res.data.msg', res);
      if (!!res.data.msg as boolean) {
        message.error({ content: res?.data?.msg, duration: 1 });
      } else {
        message.error({ content: '网络请求错误', duration: 1 });
      }
      return Promise.reject(res.data);
    }
  },
  (rej) => {
    console.log(rej);
    switch (rej.response.data.code) {
      case '401':
        console.log('跳转到登录');
        history.replace('/bh/login');
        break;
      case '402':
        console.log('登录过期!');
        history.replace('/bh/login');
        break;
      case '405':
        console.log('未获取到Token!');
        history.replace('/bh/login');
        break;
      case '504':
        console.log('用户名或密码错误！');
        break;
    }
    message.error(rej.response.data.msg || '服务器错误');
  },
);
interface ApiProps {
  url: string;
  method?: Method;
  data?: any;
  params?: any;
  responseType?: 'blob' | 'json' | 'arraybuffer';
  headers?: AxiosRequestHeaders;
  timeout?: number;
}
interface ResponseProps extends AxiosResponse {
  code: string | number;
  msg: string | null;
}
const api = async ({
  url,
  method = 'GET',
  data,
  params,
  responseType = 'json',
  headers,
  timeout,
}: ApiProps) => {
  const res: ResponseProps = await instance.request({
    url,
    method,
    data,
    params,
    responseType,
    headers,
    timeout,
  });
  return res;
};
export default api;
