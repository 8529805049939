import styles from './index.module.less';
import TitleComponents from '../components/title';
import CommonTable from '@/components/common-table';
import { NewColumnsProps } from '@/components/common-table/table.types';
import { Key, useContext, useEffect, useState } from 'react';
import {
  CancelOrderApi,
  DownLoadSourceCode,
  GetOrderListApi,
} from '@/api/order';
import { LocalStorageContext } from '@/views/provider/storage-provider';
import { Space, message } from 'antd';
import { showWarnModal } from '@/util/util';

const Order = () => {
  const LocalContext = useContext(LocalStorageContext);
  const [orderList, setOrderList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const getOrderList = async () => {
    setLoading(true);
    try {
      const { data } = await GetOrderListApi({});
      console.log(data);
      setOrderList(data?.content || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getOrderList();
  }, [LocalContext]);
  const cancelOrder = (id: Key) => {
    showWarnModal(
      '',
      async () => {
        try {
          await CancelOrderApi(id);
          message.success('取消订单成功！');
          getOrderList();
        } catch (error) {
          console.log(error);
        }
      },
      {
        okText: '确定',
        title: '是否确认取消订单？',
        cancelText: '取消',
      },
    );
  };
  const downLoad = async (orderNo: Key) => {
    try {
      await DownLoadSourceCode(orderNo);
    } catch (error) {
      console.log(error);
    }
  };
  const Columns: NewColumnsProps[] = [
    {
      key: 'orderNo',
      dataIndex: 'orderNo',
      title: '订单号',
      width: 125,
    },
    {
      key: 'productName',
      dataIndex: 'productName',
      title: '产品名称',
      width: 125,
    },
    /*{
      key: 'updateDuration',
      dataIndex: 'updateDuration',
      title: '更新期限',
    },*/
    {
      key: 'createdTime',
      dataIndex: 'createdTime',
      title: '下单时间',
      width: 100
    },
    {
      key: 'amount',
      dataIndex: 'amount',
      title: '购买金额',
      width: 100,
      render:(value)=>{
        return '￥'+value
      }
    },
    {
      key: 'payWayName',
      dataIndex: 'payWayName',
      title: '支付方式',
      width: 100
    },
    {
      key: 'payStatus',
      dataIndex: 'payStatusName',
      title: '支付状态',
      width: 100
    },
    {
      key: 'orderStatus',
      dataIndex: 'orderStatusName',
      title: '订单状态',
      width: 100
    },
/*    {
      key: 'authorizationDuration',
      dataIndex: 'authorizationDuration',
      title: '授权期限',
    },*/
    {
      key: 'action',
      dataIndex: 'action',
      title: '操作',
      fixed: 'right',
      align: 'center',
      width: 120,
      render: (_, record) => {
        const actions = [];

        if(record.orderStatus === 1){
          actions.push(
            <div className={styles['success-btn']}>
              <a href={"/order/orderResult/" + record.orderNo} target={"_blank"} >
                去支付
              </a>
            </div>
          );

          actions.push(
            <div
              className={styles['warn-btn']}
              onClick={() => {
                cancelOrder(record.id);
              }}
            >
              取消订单
            </div>
          );
        }

        return (
          <div className={styles['order-actions']} >
            <Space >
              {actions}
            </Space>
          </div>
        );
      },
    },
  ];
  return (
    <div className={styles['personal-order-wrap']}>
      <TitleComponents title="我的订单" />
      <div className={styles['personal-data-content_wrap']}>
        <CommonTable
          rowKey={"orderNo"}
          columns={Columns}
          dataSource={orderList}
          scroll={{ y: 700, x: 500 }}
          loading={loading}
          pagination={false}
        />
      </div>
    </div>
  );
};
export default Order;
