import { FC, useContext, useEffect, useMemo, useState } from 'react';
import styles from './index.module.less';
import Logo from '@/assets/images/logo.png';
import { useNavigate, useLocation } from 'react-router';
import { clearLocalstorage } from '@/util/util';
import { Dropdown, MenuProps, Space, Row, Col, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './index.less';
import { LocalStorageContext } from '@/views/provider/storage-provider';
import { LogoutApi } from '@/api/login';

interface HeaderMenuProps {
  id: string;
  name: string;
  path: string;
}
export interface HeaderProps {
  check?: number | undefined;
  userInfo: userInfoProps;
}
export type userInfoProps = {
  userNo: string;
  email: string;
  name?: string;
  phone: string;
  createdTime: string;
  sessionId?: string;
} | null;
export type UserInfoContext = Partial<userInfoProps> & {
  updateUser: (data: userInfoProps) => void;
};

type MenuItem = Required<MenuProps>['items'][number];

const menuList: MenuItem[] = [
  {
    label: '首页',
    key: '',
  },
  {
    label: '产品',
    key: 'products',
  },
  {
    label: '解决方案',
    key: 'solutions',
    /*children: [
      {
        type: 'group',
        label: 'Item 1',
      },
      {
        type: 'group',
        label: 'Item 2',
        children: [
          { label: 'Option 3', key: 'setting:3' },
          { label: 'Option 4', key: 'setting:4' },
        ],
      },
    ],*/
  },
  {
    key: 'docs',
    label: (
      <a href="//www.bpmport.com/bpmn/docs/" target="_blank" rel="flowable_plus_docs">
        文档
      </a>
    ),
  },
  {
    label: '关于我们',
    key: 'about',
  },
];

const Header: FC<HeaderProps> = (props) => {
  const navigator = useNavigate();
  const { userInfo } = props;
  const LocalContext = useContext(LocalStorageContext);
  const linkList = ['personal'];
  const [checkMenu, setCheckMenu] = useState<string | undefined>('home');
  const location = useLocation()

  useEffect(() => {
    console.log(location)
    let menuIndex = 'home';
    if(location.pathname ==='/') {
      menuIndex = 'home';
    } else if(location.pathname.indexOf('/products') === 0){
      menuIndex = 'products';
    } else if(location.pathname === '/docs'){
      // menuIndex = 1;
    } else if(location.pathname === '/license'){
      menuIndex = 'license';
    } else if(location.pathname === '/contact'){
      menuIndex = 'contact';
    }else if(location.pathname === '/contact'){
      menuIndex = 'contact';
    }else if(location.pathname === '/solutions'){
      menuIndex = 'solutions';
    }else if(location.pathname === '/cases'){
      menuIndex = 'cases';
    }else if(location.pathname === '/about'){
      menuIndex = 'about';
    }

    setCheckMenu(menuIndex);
    setCurrent(location.pathname.substring(1));

  }, [props.check]);

  const items: MenuProps['items'] = [
    {
      label: '个人信息',
      key: '0',
      onClick: () => {
        navigator('personal');
      },
    },
    {
      type: 'divider',
    },
    {
      label: '退出登录',
      key: '1',
      onClick: async () => {
        try {
          await LogoutApi();
          clearLocalstorage('userInfo');
          LocalContext?.updateUser(null);
          const flag = linkList.some(
            (item) => location.pathname.indexOf(item) > -1,
          );
          if (flag) {
            navigator('/');
          }
        } catch (error) {
          console.log(error);
        }
      },
    },
  ];
  const login = () => {
    navigator('/login');
  };

  const [current, setCurrent] = useState('home');

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
    if(e.key === 'docs'){
      return;
    }
    setCurrent(e.key);
    navigator('/' + e.key);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
  };

  return (
    <div className={styles['header-wrap']}>
      {/*<div className={styles['top-common-message']}>新版本已经发布，点击查看</div>*/}
      <div className={styles['header-content-wrap']}>
        <a href={'/'} target={"_self"} >
          <Space>
            <img src={Logo} className={styles['header-logo']}></img>
            <span style={{fontSize: '18px', fontWeight: 'bold', color: '#14bd85'}}>DragonFlow</span>
          </Space>
        </a>
        <div >
          <Menu rootClassName={"root-menu"} onClick={onClick} selectedKeys={[current]} mode="horizontal" items={menuList} />
        </div>
        <div>
          {!userInfo ? (
            <div className={styles['header-login']} onClick={login}>
              登录
            </div>
          ) : (
            <Dropdown menu={{ items }} className="header-user-button">
              <Space>
                {userInfo!.userNo}
                <DownOutlined style={{ color: '#14BD85' }} />
              </Space>
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
