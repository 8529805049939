import React from 'react';
import '../index.less';
import AppWrapper from '@/routes/index';
import zhCn from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd';
import { createRoot } from 'react-dom/client';
/**
 * 如果是生产环境初始化sentry，上传sourcemap
 *
 */

if (module.hot) {
  console.log(module.hot);
  module.hot.accept();
}
const container = document.getElementById('main-root')!;
const rootElement = (
  <ConfigProvider locale={zhCn}>
    <AppWrapper />
  </ConfigProvider>
);
const root = createRoot(container);
root.render(rootElement);
