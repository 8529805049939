import {useContext, useMemo} from 'react';
import {Button, Tag, Statistic} from "antd";
import {setLocalStorage} from "@/util/util";
import { useNavigate } from 'react-router';
import {LocalStorageContext} from "@/views/provider/storage-provider";
const BuyButton = ({productNo}) => {
  const navigator = useNavigate();
  const userInfo = useContext(LocalStorageContext);

  const goodsList = [
    {"id":"1","productName":"企业版流程设计器Flowable版","productNo":"PT0001","amount":1999,"originalPrice":5999,"discount":null,"introduce":"支持Flowable,支持Vue2.x/Vue3.x,提供源码、允许二开,常用的组件扩展功能,强大的决策设计与渲染,开具电子发票,企业版BPMN设计器,技术支持 7个工作日"},
    {"id":"2","productName":"FlowablePlus插件版","productNo":"GJ0001","amount":19999,"originalPrice":39999,"discount":"","introduce":"支持Vue2.x/Vue3.x,提供源码、允许二开,常用的组件扩展功能,开具电子发票,企业版BPMN设计器,DMN决策引擎,强大的服务编排,支持审批、驳回、前加签、后加签、暂存、转办、签收、撤回、转阅,支持待办、已办、我发起等功能,支持redis缓存模型数据,支持Rest、消息、邮件、抄送、微服务等服务任务,替换flowable原生的de_model,实现6.x到7.x的无缝升级,技术支持 1个月（工作日）"},
    {"id":"4","productName":"企业版流程设计器Activiti版","productNo":"PT0002","amount":1699,"originalPrice":5999,"discount":"","introduce":"支持Activiti,常支持Vue2.x/Vue3.x,提供源码、允许二开,常用的组件扩展功能,开具电子发票,企业版BPMN设计器,技术支持 7个工作日"}
  ];

  const toBuy = (proNo) => {
    if (!userInfo) {
      navigator('/login');
    } else {
      let goodsInfo = goodsList.find(item => item.id == proNo);

      setLocalStorage('goodsInfo', goodsInfo);
      navigator('/order', { state: goodsInfo });
      window.scrollTo(0, 0); // 滚动到页面顶部
    }
  };


  return (
    <div style={{margin: '10px auto'}}>
      <Button onClick={() => {toBuy(productNo)}} size={"middle"}  style={{ width: '80%', color: '#fff', backgroundColor: '#2d8cf0', borderColor: '#2d8cf0'}}>立即购买</Button>
    </div>
  );
};
export default BuyButton;
